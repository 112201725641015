import { navigate } from 'gatsby';
import { planDetailsV2 } from '../constants/Plans';
import { Cookies } from './Helper';
import { SALES_NO } from '../constants/Enum';

export default class Utility {
    static getCurrencySymbol(currencyCodeValue) {
        if (currencyCodeValue) {
            const currencyCode = currencyCodeValue.toString().toUpperCase();
            if (currencyCode === `USD` || currencyCode === `SGD` || currencyCode === `AUD`) {
                return `$`;
            } else if (currencyCode === `EUR`) {
                return `€`;
            } else if (currencyCode === `INR`) {
                return `₹`;
            } else if (currencyCode === `IDR`) {
                return `Rp`;
            }
            return currencyCode;
        }
        return currencyCodeValue;
    }
    static openInNewTab(url) {
        window.open(url, `_blank`);
    }
    static getPriceValue = (plan, countryCode = `us`) => {
        const price = planDetailsV2[plan].pricing[countryCode];

        return price;
    };
    static toCurrencyFormat = (number) => {
        if (isNaN(number)) {
            return ``;
        }
        return parseFloat(`` + number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1,`);
    };
    static scrollToId = (elementId) => {
        const element = document.getElementById(elementId);
        element.scrollIntoView({ behavior: `smooth` }, true);
    };
}

export function getCapitalized(name) {
    const str = name.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isValidEmail(email) {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isValidURL(string) {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
}

export function isValidNumber(value) {
    return !isNaN(value) && value.trim() !== ``;
}

export function setPersistentValue(value, key) {
    if (typeof window !== `undefined`) {
        localStorage.setItem(key, value);
    }
}

export function getPersistentValue(key) {
    if (typeof window !== `undefined`) {
        return localStorage.getItem(key);
    }
    return null;
}

export function isMobile() {
    if (typeof window !== `undefined` && window.innerWidth <= 800) {
        return true;
    }
    return false;
}

export function getMixColorText(str, color) {
    const pattern = /\](.*?)\]/;
    const match = pattern.exec(str);
    const result = match?.[1]; // "world"

    const arr = str.split(`]`);

    let text = ``;
    arr.forEach((element) => {
        if (element !== ``) {
            if (element === result) {
                text += `<span class=` + color + ` >` + element + `</span>`;
            } else {
                text += `<span class="text-black">` + element + `</span>`;
            }
        }
    });

    return text;
}

export function getMixColorTextTailwind(str, color) {
    const pattern = /\](.*?)\]/;
    const match = pattern.exec(str);
    const result = match?.[1]; // "world"

    const arr = str.split(`]`);

    let text = ``;
    arr.forEach((element) => {
        if (element !== ``) {
            if (element === result) {
                text += `<span class=text-` + color + ` >` + element + `</span>`;
            } else {
                text += `<span class="text-black">` + element + `</span>`;
            }
        }
    });

    return text;
}

export function navigateToPage(pageUrl) {
    navigate(pageUrl);
}

/**
 * Create unique keys to be used while creating lists
 * @param {string} prefix
 * @param {string} uniqueStr
 * @returns string
 */
export const getUniqueKey = (prefix, uniqueStr) => `${prefix}-`.concat(uniqueStr.split(` `).join(`-`).concat(`-key`));

export function isEmpty(value) {
    if (value === null || value === undefined || value.length === 0) return true;
    if (Array.isArray(value) || typeof value === `string`) return !value.length;
    return Object.keys(value).length === 0;
}

export function getTimeInSeconds(intervalInMinutes) {
    const seconds = intervalInMinutes * 60;
    return seconds;
}
export function getCountryWiseUrl(data) {
    if (Cookies.get(`location`)) {
        switch (Cookies.get(`location`)) {
            case `IN`:
                return data.inUrl ? data.inUrl : data.url;
            case `MY`:
                return data.myUrl ? data.myUrl : data.url;
            default:
                return data.url;
        }
        // return Cookies.get('location') === 'India' ? (data.inUrl ? data.inUrl : data.url) : data.url;
    } else {
        return isIndiaTimezone() ? (data.inUrl ? data.inUrl : data.url) : data.url;
    }
}
export function getCountryWiseCurrency() {
    if (Cookies.get(`location`)) {
        switch (Cookies.get(`location`)) {
            case `IN`:
                return `inr`;
            default:
                return `usd`;
        }
    } else {
        return `usd`;
    }
}
export function isIndiaTimezone() {
    if (new Date().toString().includes(`(IST)`) || new Date().toString().includes(`(India Standard Time)`)) {
        return true;
    }
    return false;
}
export function isLocationIndia() {
    if (Cookies.get(`location`)) {
        return Cookies.get(`location`) === `India` || Cookies.get(`location`) === `IN`;
    }
    return isIndiaTimezone();
}
export function isGetLocationBasedSalesNumber() {
    switch (Cookies.get(`location`)) {
        case `IN`:
            return SALES_NO.IN;
        case `SG`:
            return SALES_NO.SG;
        default:
            return SALES_NO.GLOBAL;
    }
}
export function getLocationBasedPageUrls(url) {
    switch (Cookies.get(`location`)) {
        case `IN`:
            if (url.indexOf(`http://`) == 0 || url.indexOf(`https://`) == 0) {
                return url;
            }
            return '/in' + url;
        default:
            return url;
    }
}
export function isIndiaPage() {
    if (typeof window !== `undefined`) {
        return window.location.href.includes('/in/');
    }
    return false;
}
export function getTalktoSalesUrl() {
    switch (Cookies.get(`location`)) {
        case `IN`:
            return `/in/pricing#talk-to-sales`;
        case `MY`:
            return `/my/pricing#talk-to-sales`;
        default:
            return `/us/pricing#talk-to-sales`;
    }
}
export function generateTransactionId() {
    if (Cookies.get(`transactionId`)) {
        return Cookies.get(`transactionId`);
    } else {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const unixTimestamp = Math.floor(Date.now() / 1000);
        Cookies.set(`transactionId`, `T-${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${unixTimestamp}`, 1);
        return `T-${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${unixTimestamp}`;
    }
}
