export const ENV = {
    DEV: `DEV`,
    QA: `QA`,
    QA2: `QA2`,
    STAGING: `STAGING`,
    PROD: `PROD`
};
export const PRODUCTS = {
    ERP: `erp`,
    CRM: `crm`,
    PEOPLE: `people`,
    AIO: `aio`,
    MRP: `mrp`
};

export const PLANS = {
    STARTUP: `startup`,
    ESSENTIAL: `essential`,
    PROFESSIONAL: `professional`,
    CUSTOM: `custom`,
    MRP: `mrp`
};
export const PLANS_V2 = {
    ERP: `erp`,
    ERP_PLUS: `erp_plus`,
    MRP_PLUS: `mrp_plus`
};
export const PLANS_NAME_V2 = {
    ERP: `ERP`,
    ERP_PLUS: `ERP+`,
    MRP_PLUS: `MRP+`
};
export const PRICING_PLANS_NAME_V2 = {
    STANDARD_PLAN: `Standard Plan`,
    BUSINESS_PLAN: `Business Plan`,
    PROFESSIONAL_PLAN: `Professional Plan`,
    SMALL_BUSINESS: `Small Business`,
    GROWTH: `Growth`,
    MID_MARKET: `Mid Market`,
    ENTERPRISE: `Enterprise`
};
export const INTERVALS = {
    YEARLY: `yearly`,
    MONTHLY: `monthly`
};

export const ALIGNMENT = {
    LEFT: `left`,
    RIGHT: `right`,
    CENTER: `center`,
    JUSTIFY: `justify`
};

export const FORM_TYPE = {
    BOOK_A_DEMO: `BOOK_A_DEMO`,
    SIGN_UP_LEAD_GENERATION: `SIGN_UP_LEAD_GENERATION`,
    SIGN_UP_FORM: `SIGN_UP_FORM`,
    CONTACT_US: `CONTACT_US`
};

export const API_STATUS = {
    SUCCESS_200: 200,
    SUCCESS_201: 201,
    LOADING: 'loading',
    IDLE: 'idle',
    SUCCESS: `success`
};
export const INPUT_VIEW_DIRECTION = {
    HORIZONTAL: `horizontal`,
    VERTICAL: `vertical`
};
export const SALES_NO = {
    GLOBAL: `Sales: 888 690 3830`,
    IN: `Sales: +91 80 3521 6752`,
    SG: `Sales: +65 6202 1350`
};

/* Common KeyboardEvent.key codes */
export const KEY_CODES = {
    ARROW_UP: `ArrowUp`,
    ARROW_DOWN: `ArrowDown`,
    ARROW_LEFT: `ArrowLeft`,
    ARROW_RIGHT: `ArrowRight`,
    ENTER: `Enter`,
    ESCAPE: `Escape`
};
export const CRM_FORMS_NAME = {
    competitor: `Competitor Form`,
    book_a_demo: `Book A Demo Form`,
    signup: `Signup Form`,
    register: `Register Form`,
    partner_form: `Partner Enquiry Form`,
    contact_us: `Contact Us Form`
};
export const DEFAULT_CURRENCY = `usd`;
