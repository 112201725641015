import { getLocationBasedPageUrls } from '../utility/Utility';

export const TRY_BUTTON_TITLE = `Start 15 days trial`;
export const START_TRY_BUTTON_TITLE = `Start 15 days trial`;
export const TRY_BUTTON_TITLE_NO_ARROW = `Start 15 days trial`;
export const START_FREE_TRIAL = `Start Free trial`;
export const REQUEST_DEMO = `Request Demo`;
export const CONTACT_SALES = `Contact Sales`;
export const DESKERA_WEBSITE_URL = process.env.GATSBY_DESKERA_WEBSITE_URL;
export const WEBSITE_URL = process.env.GATSBY_WEBSITE_URL;

export const SEO_IMAGE_WIDTH = 1200;
export const SEO_IMAGE_HEIGHT = 880;

export var pageUrls = {
    HOME: ``,
    HOME_IN: `/in`,
    SIGN_UP: `/signup`,
    SIGN_IN: `/signin`,
    AIO: `/all-in-one`,
    BOOKS: `/books`,
    CRM: `/crm`,
    MRP: `/mrp`,
    PEOPLE: `/people`,
    ABOUT_US: `/about-us`,
    BUSINESS_SOFTWARE_SG: `/business-software-sg`,
    BUSINESS_SOFTWARE_MY: `/business-software-my`,
    BOOK_A_DEMO: `/book-a-demo`,
    PRICING: `/pricing`,
    BUYING_AND_PURCHASE_ORDER: `/books/buying-and-purchase-orders`,
    CREATING_QUOTES: `/books/creating-quotes`,
    PAYING_BILLS: `/books/paying-bills-with-deskera`,
    INVENTORY_MANAGEMENT_SOFTWARE: `/inventory-management-software`,
    ACCOUNTING_SOFTWARE: `/accounting-software`,
    BACK_ORDER: `/books/back-orders-with-deskera-books`,
    CHART_OF_ACCOUNTS_WITH_DESKERA_BOOK: `/books/chart-of-accounts-with-deskera-books`,
    REPORTS: `/books/reports`,
    QUICK_BOOKS_ALTERNATIVE: `/quickbooks-alternative`,
    BOOK_KEEPER: `/bookkeeper`,
    WEBSITE_BUILDER: `/website-builder`,
    BANK_INTEGRATION: `/books/bank-integration`,
    CREATING_CREDIT_NOTES_IN_DESKERA: `/books/creating-credit-notes-in-deskera`,
    CONTACTS: `/books/contacts`,
    TAX_FILING: `/books/tax`,
    DEBIT_NOTES_WITH_DESKERA: `/books/debit-notes-with-deskera`,
    CUSTOM_FIELDS_IN_DESKERA: `/books/custom-fields-in-deskera`,
    DEPOSITS: `/books/deposits-with-deskera`,
    DEALS_WITH_DESKERA_SALES: `/crm/deals-with-deskera-sales`,
    BOOK_EXPENSES: `/books/expenses`,
    JOURNAL_ENTRIES: `/accounting/journal-entries`,
    MANUFACTURING_BUSINESSES: `/industries/manufacturing-companies`,
    HEALTHCARE: `/industries/healthcare`,
    ECOMMERCE: `/industries/ecommerce`,
    IT_SERVICES: `/industries/it-services`,
    PAYROLL: `/people/payroll`,
    EXPENSES: `/people/expenses`,
    REPORT_BUILDER: `/report-builder`,
    BOOK_KEEPING: `/bookkeeping-software`,
    PAYSLIP: `/payslip`,
    BILLING_SOFTWARE: `/billing-software`,
    GENERAL_LEDGER: `/accounting/general-ledger`,
    HELPDESK: `/crm/helpdesk`,
    OPENING_BALANCE: `/books/opening-balances-with-deskera`,
    ATTENDANCE_LEAVES: `/people/attendance-and-leave`,
    CUSTOMER_RELATIONSHIP_MANAGEMENT_CONTACTS: `/crm/customer-relationship-management-contacts`,
    EMAIL_CAMPAIGN: `/crm/email-campaign`,
    USER_ROLE_MANAGEMENT_WITH_DESKERA: `/crm/user-role-management-with-deskera`,
    WHY_DESKERA: `/why-deskera`,
    CURRENT_OPENINGS: `https://www.linkedin.com/jobs/deskera-jobs`,
    CURRENT_OPENINGS_LIST: `/careers/current-openings`,
    CONTACT_US: `/contact-us`,
    SHOP: `/shop`,
    BANK_CONNECT_AND_BANK_RECONCILIATION: `/books/bank-connect-and-bank-reconciliation`,
    HRMS: `/hrms`,
    MOBILE_HRMS: `/hrms/mobile-hrms`,
    PERSONNEL_ADMINISTRATION: `/hrms/personnel-administration`,
    HRMS_EMPLOYEE_SELF_SRVICE: `/hrms/employee-self-service`,
    HRMS_RECRUITMENT_MANAGEMENT: `/hrms/recruitment-management`,
    HRMS_PERFORMANCE_APPRAISAL: `/hrms/performance-appraisal`,
    WORKFLOW: `/workflow`,
    WORKFLOW_AUTOMATION: `/workflow-automation`,
    DESKERA_MOBILE: `/deskera-mobile`,
    HRIS: `/hris`,
    RECEIPTS: `/receipts`,
    INDUSTRIES: `/industries`,
    TESTIMONIALS: `/testimonials`,
    AFFILIATE: `/affiliate`,
    WHAT_IS_ERP: `/what-is-erp`,
    WHY_DESKERA_ERP: `/why-deskera-erp`,
    FINANCIAL_REPORTS: `/erp/financial-reports`,
    ECLAIMS_REPORTS: `/eclaims/reports`,
    SG_GST: `/sg/gst`,
    ECLAIMS: `/eclaims`,
    IN_GST: `/in/gst`,
    MY_SST: `/my/sst`,
    PROJECT_MANAGEMENT: `/project-management`,
    BENEFITS_OF_CLOUD_ERP: `/benefits-of-cloud-erp`,
    WHAT_IS_MOBILE_ERP: `/what-is-mobile-erp`,
    VENDOR_MANAGEMENT: `/erp/vendor-management`,
    MOBILE_LMS: `/lms/mobile-lms`,
    WOOCOMMERCE_DESKERA: `/in/woocommerce-deskera`,
    WEBINAR: `//webinar`,
    SOCIAL_MEDIA: `/lms/social-media`,
    ERP_FOR_SERVICES: `//erp-for-services`,
    ERP_FOR_AVIATION: `/erp-for-aviation`,
    DATA_PROTECTION_POLICY: `/data-protection-policy`,
    COOKIE_POLICY: `/cookie-policy`,
    MRP_WORK_ORDER: `/mrp/work-order`,
    MRP_LABOR_MASTER: `/mrp/labor-master`,
    ERP_SINGAPURE_GST: `/erp/singapore-gst`,
    ERP_MALAYSIAN_GST: `/erp/malaysian-gst`,
    TIMESHEET_MANAGEMENT: `/hrms/timesheet-management`,
    GDPR: `/gdpr`,
    DATA_PROCESSING_AGREEMENT: `/data-processing-agreement`,
    DATA_PROCESSING_ADDENDUM: `/data-processing-addendum`,
    PRIVACY_POLICY: `/privacy-policy`,
    TOS: `/terms-of-service`,
    SUBPROCESSORS: `/subprocessors`,
    INVOICE: `/invoice`,
    REGISTER: `/register`,
    SALES_PIPELINE: `/crm/sales-pipeline`,
    BUILD_LANDING_PAGES: `/crm/build-landing-pages`,
    CONVERSION_FUNNELS: `/crm/conversion-funnels`,
    MARKETING_AUTOMATION: `/crm/automation-sales-marketing`,
    ACTIVITY_MANAGEMENT: `/crm/activity-management`,
    LEAD_FORMS: `/crm/lead-forms`,
    HIRING_AUTOMATION: `/people/hiring-automation`,
    DOWNLOAD_DESKERA_APP: `/in/download-deskera-app`,
    BOOK_E_INVOICING: `/in/books/e-invoicing`,
    ONLINE_INVOICE_PAYMENTS: `/books/online-invoice-payments`,
    CALL_OUR_SME_EXPERT: `/in/call-our-sme-expert`,
    HIRE_APPLICANT: `/people/hiring-automation`,
    ACCEPT_AND_RECEIVE_PAYMENTS_WITH_STRIPE: `/books/accept-and-receive-payments-with-stripe`,
    PURCHASE_MANAGEMENT: `/erp/purchase-management`,
    PRODUCT_MANAGEMENT: `/erp/product-management`,
    ACCOUNT_MANAGEMENT: `/crm/account-management`,
    STOCK_MANAGEMENT: `/erp/stock-management`,
    ITEM_MASTER_MANAGEMENT: `/erp/item-master-management`,
    CHECKLIST_MANAGEMENT: `/erp/checklist-management`,
    STATUTORY_COMPLIANCE: `/erp/statutory-compliance`,
    CUSTOMER_MANAGEMENT: `/erp/customer-management`,
    STUDENT_ADMISSIONS: `/lms/student-admissions`,
    INSTITUTIONAL_FINANCE_MANAGEMENT: `/lms/institutional-finance-management`,
    VIRTUAL_CLASSROOM: `/lms/virtual-classroom`,
    LMS: `/lms`,
    BUSINESS_DASHBOARD: `/books/business-dashboard`,
    EXAMINATION_MANAGEMENT: `/lms/examination-management`,
    LEARNING_PORTFOLIO_MANAGEMENT: `/lms/learning-portfolio-management`,
    STUDENT_MANAGEMENT: `/lms/student-management`,
    COURSE_MANAGEMENT: `/lms/course-management`,
    CAMPUS_MANAGEMENT: `/lms/campus-management`,
    FACULTY_MANAGEMENT: `/lms/faculty-management`,
    MULTI_CURRENCY: `/books/multi-currency`,
    LEARNING_CONTENT_MANAGEMENT: `/lms/learning-content-management`,
    DOCUMENT_MANAGEMENT: `/lms/document-management`,
    WORK_CENTER_MASTER: `/mrp/work-center-master`,
    MASTER_PRODUCTION_SCHEDULE: `/mrp/master-production-schedule`,
    DEMAND_FORECASTING_AND_PLANNING: `/mrp/demand-forecasting-and-planning`,
    PRODUCT_MASTER: `/mrp/product-master`,
    ROUTING_MASTER: `/mrp/routing-master`,
    QUALITY_CONTROL: `/mrp/quality-control`,
    MACHINE_MASTER: `/mrp/machine-master`,
    DELIVERY_PLANNING: `/mrp/delivery-planning`,
    ERP_CLOUD: `/erp-cloud`,
    EDUCATION_CLOUD: `/education-cloud`,
    HRMS_CLOUD: `/hrms-cloud`,
    INVOICE_TEMPLATE: `/books/invoice-templates`,
    MIGRATION: `/migration`,
    ERP: `/erp`,
    ACCOUNTING: `/accounting`,
    FINANCIAL_MANAGEMENT: `/financial-management`,
    ORDER_MANAGEMENT: `/order-management`,
    PROCUREMENT: `/procurement`,
    INVENTORY_MANAGEMENT: `/inventory-management`,
    WAREHOUSE_MANAGEMENT: `/warehouse-management`,
    PROJECT_ACCOUNTING: `/project-accounting`,
    CUSTOMER_SERVICE: `/customer-service`,
    BUSINESS_INTELLIGENCE: `/business-intelligence`,
    DEMAND_FORECASTING: `/demand-forecasting`,
    MATERIAL_REQUIREMENT_PLANNING: `/material-requirement-planning`,
    MULTI_LEVEL_BILL_OF_MATERIAL: `/multi-level-bill-of-materials`,
    MACHINE_MANAGEMENT: `/machine-management`,
    BY_PRODUCT_MANAGEMENT: `/by-product-scrap-management`,
    YIELD_FINISHED: `/yield-finished-good-cost`,
    PRODUCTION_REPORT: `/production-reports`,
    CONTRACT_MANUFACTURING: `/contract-manufacturing`,
    WORK_ORDER_MANAGEMENT: `/work-order-management`,
    PRODUCTION_PLANNING: `/production-planning`,
    WIP_TRACKING: `/wip-tracking`,
    ACCOUNT_RECEIVABLE: `/accounting/accounts-receivable`,
    ACCOUNT_PAYABLE: `/accounting/accounts-payable`,
    BANK_RECONCILIATION: `/accounting/bank-reconciliation`,
    COMPLIANCE: `/accounting/compliance`,
    MULTI_CURRENCY_ACCOUNTING: `/accounting/multi-currency-accounting`,
    REVENUE_RECOGNITION: `/financial-management/revenue-recognition`,
    FINANCIAL_YEAR_CLOSING: `/accounting/financial-year-closing`,
    MULTI_CHANNEL_ORDER_MANAGEMENT: `/order-management/multi-channel-order-management`,
    ORDER_FULFILLMENT: `/order-management/order-fulfillment`,
    RESERVE_STOCK: `/order-management/reserve-stock`,
    PURCHASE_REQUISITIONS: `/procurement/purchase-requisitions`,
    RFQS: `/procurement/rfqs-vendor-quotations`,
    FIXED_ASSET_ACCOUNTING: `/accounting/fixed-asset-accounting`,
    BUDGET_FORECASTING: `/financial-management/budget-forecasting`,
    MULTI_BOOK_ACCOUNTING: `/financial-management/multi-book-accounting`,
    REVALUATION: `/financial-management/revaluation`,
    ROLE_BASED_ACCESS: `/financial-management/role-based-access`,
    MULTI_ENTITY_CONSOLIDATION: `/financial-management/multi-entity-consolidation`,
    APPROVAL_WORKFLOWS_FIN: `/financial-management/approval-workflows`,
    FINANCIAL_REPORTING: `/financial-management/financial-reporting`,
    PICK_PACK_SHIP: `/order-management/pick-pack-ship`,
    DROPSHIP: `/order-management/dropship`,
    PURCHASE_ORDERS: `/procurement/purchase-orders`,
    PURCHASE_INVOICES: `/procurement/purchase-invoices`,
    APPROVAL_WORKFLOWS: `/procurement/approval-workflows`,
    PAYMENT_MILESTONES: `/project-accounting/payment-milestones`,
    PROJECT_COSTING_AND_P_AND_L: `/project-accounting/project-costing-and-p-and-l`,
    EMAIL_HELPDESK: `/customer-service/email-helpdesk`,
    CHAT_WIDGET: `/customer-service/chat-widget`,
    SUPPORT_TICKETS: `/customer-service/support-tickets`,
    FORECASTS: `/business-intelligence/forecasts`,
    SMART_REPORTS: `/business-intelligence/smart-reports`,
    DASHBOARDS: `/business-intelligence/dashboards`,
    CONTACT_MANAGEMENT: `/crm/contact-management`,
    CONTACT_SEGMENTATION: `/crm/contact-segmentation`,
    SALES_PIPELINE_MANAGEMENT: `/crm/sales-pipeline-management`,
    OPPORTUNITY_MANAGEMENT: `/crm/opportunity-management`,
    MARKETING_FORMS: `/crm/marketing-forms`,
    LANDING_PAGES: `/crm/landing-pages`,
    EMAIL_CAMPAIGNS: `/crm/email-campaigns`,
    DRIP_EMAIL_SEQUENCES: `/crm/drip-email-sequences`,
    RULE_BASED_AUTOMATIONS: `/crm/rule-based-automations`,
    BILL_OF_QUANTITIES: `/project-accounting/bill-of-quantities`,
    PROJECT_TIME_TRACKING: `/project-accounting/project-time-tracking`,
    MULTIPLE_WAREHOUSES: `/warehouse-management/multiple-warehouses`,
    ROW_RACK_BIN: `/warehouse-management/row-rack-bin`,
    STOCK_TRANSFER_AND_ADJUSTMENTS: `/warehouse-management/stock-transfer-and-adjustments`,
    RECRUITMENT_MANAGEMENT: `/hrms/recruitment-management`,
    EMPLOYEE_DATABASE_MANAGEMENT: `/hrms/employee-database-management`,
    ATTENDANCE_AND_LEAVE_MANAGEMENT: `/hrms/attendance-and-leave-management`,
    CLAIMS_AND_EXPENSE_MANAGEMENT: `/hrms/claims-expense-management`,
    PAYROLL_MANAGEMENT: `/hrms/payroll-management`,
    PERFORMANCE_MANAGEMENT: `/hrms/performance-management`,
    TIMESHEET_TRACKING: `/hrms/timesheet-tracking`,
    SHIFT_MANAGEMENT: `/hrms/shift-management`,
    LOT_BATCH_AND_SERIAL_TRACKING: `/inventory-management/lot-batch-and-serial-tracking`,
    UOM_SCHEMA: `/inventory-management/uom-schema`,
    CYCLE_COUNT_AND_STOCK_ADJUSTMENTS: `/inventory-management/cycle-count-and-stock-adjustments`,
    FULFILLMENT_TRACKING: `/inventory-management/fulfillment-tracking`,
    INVENTORY_ANALYSIS: `/inventory-management/inventory-analysis`,
    INVENTORY_PRICING: `/inventory-management/inventory-pricing`,
    INVENTORY_REPORTING: `/inventory-management/inventory-reporting`,
    LANDED_COST_OF_INVENTORY: `/inventory-management/landed-cost-of-inventory`,
    INVENTORY_AUTOMATIONS: `/inventory-management/inventory-automations`,
    BILL_OF_MATERIALS: `/inventory-management/bill-of-materials`,
    BARCODE_GENERATION_AND_SCANNING: `/inventory-management/barcode-generation-and-scanning`,
    INVENTORY_REPLENISHMENT: `/inventory-management/inventory-replenishment`,
    MULTI_WAREHOUSE_INVENTORY_TRACKING: `/inventory-management/multi-warehouse-inventory-tracking`,
    BLOG: `/blog`,
    DEVELOPERS: `https://deskera.github.io/Developer-Documentation/`,
    WEB_TO_LEAD: `/crm/web-to-lead-form`,
    PPT_VAN: `/books/ppn-vat`,
    GST_BENEFITS_ON_INDIAN_ECONOMY: `in/gst-benefits-and-impact-on-indian-economy`,
    PARTNER: `/partner`,
    ENTERPRISES_PLAN: `/enterprise`,
    GROWTH_PLAN: `/growth`,

    REGIONAL_BUSINESSES: `/industries/regional-businesses`,
    COMPUTER_AND_ELECTRONICS_MANUFACTURERS: `/industries/computer-and-electronics-manufacturers`,
    SOLAR_ENERGY_SYSTEMS_AND_SERVICES: `/industries/solar-energy-systems-and-services`,
    MEDICAL_SUPPLIES_MANUFACTURERS: `/industries/medical-supplies-manufacturers`,
    MANUFACTURING: `/industries/manufacturing`,
    AUTOMOBILE_MANUFACTURERS_AND_SERVICES: `/industries/automobile-manufacturers-and-services`,
    SEMICONDUCTOR_MANUFACTURERS: `/industries/semiconductor-manufacturers`,
    SOFTWARE_AND_TECHNOLOGY_COMPANIES: `/industries/software-and-technology-companies`,
    ADVERTISING_AND_DIGITAL_COMPANIES: `/industries/advertising-and-digital-companies`,
    APPAREL_AND_FOOTWEAR_COMPANIES: `/industries/apparel-and-footwear-companies`,
    CONSULTING_FIRMS: `/industries/consulting-firms`,
    EDUCATION_INSTITUTIONS: `/industries/education-institutions`,
    ENERGY_COMPANIES: `/industries/energy-companies`,
    FINANCIAL_SERVICES_FIRMS: `/industries/financial-services-firms`,
    FOOD_AND_BEVERAGE_COMPANIES: `/industries/food-and-beverage-manufacturers`,
    HEALTH_AND_BEAUTY_COMPANIES: `/industries/health-and-beauty-companies`,
    MARKETING_AGENCIES: `/industries/marketing-agencies`,
    MEDIA_AND_PUBLISHING_COMPANIES: `/industries/media-and-publishing-companies`,
    NONPROFIT_INSTITUTIONS: `/industries/nonprofit-institutions`,
    PROFESSIONAL_SERVICES: `/industries/professional-services`,
    TRANSPORTATION_AND_LOGISTICS_COMPANIES: `/industries/transportation-and-logistics-companies`,
    WHOLESALE_DISTRIBUTION_COMPANIES: `/industries/wholesale-distribution-companies`,
    HIGH_TECH_AND_ELECTRONICS: `/industries/high-tech-and-electronics`,
    APPAREL_CLOTHING_TEXTILE: `/industries/apparel-clothing-textile`,
    FOOD_MANUFACTURING_ERP: `/industries/food-manufacturing-erp`,
    PLASTIC_MANUFACTURING: `/industries/plastic-manufacturing`,
    METAL_FABRICATION: `/industries/metal-fabrication`,
    INDUSTRIAL_MACHINERY_AND_EQUIPMENT: `/industries/industrial-machinery-equipment-manufacturing`,
    CONSTRUCTION_ENGINEERING: `/industries/construction-and-engineering`,
    FURNITURE_MANUFACTURING: `/industries/furniture-manufacturing`,
    INDUSTRIAL_MANUFACTURING: `/industries/industrial-manufacturing`,
    MEDICAL_DEVICE_MANUFACTURING: `/industries/medical-device-manufacturing`,
    AUTOMOTIVE_MANUFACTURING: `/industries/automotive-manufacturing`,
    AEROSPACE_AND_DEFENCE: `/industries/aerospace-and-defence`,
    SCREW_SHOP: `/industries/screw-shop-manufacturing`,
    SPRING_SHOP_MANUFACTURING: `/industries/spring-shop-manufacturing`,

    DESKERA_VS_ACUMATICA: `/competitors/deskera-vs-acumatica`,
    DESKERA_VS_D365: `/competitors/deskera-vs-d365`,
    DESKERA_VS_EPICOR: `/competitors/deskera-vs-epicor`,
    DESKERA_VS_INFOR: `/competitors/deskera-vs-infor`,
    DESKERA_VS_NETSUITE: `/competitors/deskera-vs-netsuite`,
    DESKERA_VS_ODOO: `/competitors/deskera-vs-odoo`,
    DESKERA_VS_SAGE_INTACCT: `/competitors/deskera-vs-sage-intacct`,

    MANUFACTURING_SOFTWARE: `/manufacturing-software`,
    MRP_SOFTWARE: `/mrp-software`,
    PROCESS_MANUFACTURING: `/process-manufacturing`,
    MANUFACTURING_ERP_SOFTWARE: `/manufacturing-erp-software`,
    MANUFACTURING_INVENTORY: `/manufacturing-inventory`,
    MANUFACTURING_ACCOUNTING: `/manufacturing-accounting`,

    SOLUTIONS_CEO: `/solutions/ceo`,
    SOLUTIONS_MID_MARKET: `/solutions/mid-market`,
    SOLUTIONS_BUSINESS: `/solutions/business`,
    SOLUTIONS_FINANCE: `/solutions/finance`,
    SOLUTIONS_CFO: `/solutions/cfo`,
    SOLUTIONS_OPERATIONS: `/solutions/operations`,
    SOLUTIONS_SALES_AND_MARKETING: `/solutions/sales-and-marketing`,
    SOLUTIONS_IT: `/solutions/it`,
    SOLUTIONS_CONTROLLER: `/solutions/controller`,
    SOLUTIONS_CIO: `/solutions/cio`,
    SOLUTIONS_ROLES: `/solutions/roles`,
    SOLUTIONS_SIZE: `/solutions/size`,

    AI: `/ai`,
    FOOTWEAR_MANUFACTURING: `/footwear-manufacturing`,

    COMPARISON: `/comparison`,
    COMPARISON_DYNAMICS365: `/comparison/dynamics365`,
    COMPARISON_NETSUITE: `/comparison/netsuite`,
    COMPARISON_ACUMATICA: `/comparison/acumatica`,
    COMPARISON_ODOO: `/comparison/odoo`,
    COMPARISON_EPICOR: `/comparison/epicor`,
    COMPARISON_INFOR: `/comparison/infor`,
    COMPARISON_JOBBOSS: `/comparison/jobboss`,
    COMPARISON_FISHBOWL: `/comparison/fishbowl`,
    COMPARISON_KATANA: `/comparison/katana`,
    COMPARISON_SAGE: `/comparison/sage`,
    COMPARISON_SAP_BUSINESS_ONE: `/comparison/sap-business-one`,
    COMPARISON_QUICKBOOKS: `/comparison/quickbooks`,

    SERVICES_IMPLEMENTATION: `/services/implementation`
};

export const SEND_RESUME_TO = process.env.GATSBY_SEND_RESUME_TO;
export const CAMPAINGN_PLAN_ID = process.env.GATSBY_CAMPAINGN_PLAN_ID;
export const CURRENT_ENV = process.env.GATSBY_ENV;

export const MIX_PANEL_ENABLED = process.env.GATSBY_APP_MIXPANEL_ENABLED;
export const MIX_PANEL_TOKEN = process.env.GATSBY_APP_MIXPANEL_KEY;

export const VIEWPORT_SIZES = {
    EXTRA_SMALL: `xs`,
    SMALL: `sm`,
    LARGE: `lg`
};

export const BREAKPOINTS = {
    SMALL: 560,
    LARGE: 1024
};
export const INTERCOM_EVENTS = {
    CONTACT_US_FORM: `contact_us_form`,
    SIGNUP_FORM: `Signup Form`,
    SIGNIN_FORM: `Signin`,
    PAGE: `Page`,
    BOOK_A_DEMO_FORM: `Book A Demo Form`,
    PARTNER_FORM: `Partner Form`,
    REGISTER_WEBINAR_FORM: `Register Webinar Form`,
    COMPETITOR_FORM: `Competitor Form`,
    EXIT_FROM_PAGE: `exit event`
};
export const GOOGLE_ANALYTICS = {
    CONTACT_US_FORM: `contact_us_form`,
    SIGNUP_FORM: `Signup Form`,
    SIGNIN_FORM: `Signin`,
    PAGE: `Page`,
    BOOK_A_DEMO_FORM: `Book A Demo Form`,
    PARTNER_FORM: `Partner Form`,
    REGISTER_WEBINAR_FORM: `Register Webinar Form`,
    COMPETITOR_FORM: `Competitor Form`,
    CTA_CLICK: `CTA_click`
};
