exports.components = {
  "component---src-layouts-gcms-page-template-jsx": () => import("./../../../src/layouts/gcmsPageTemplate.jsx" /* webpackChunkName: "component---src-layouts-gcms-page-template-jsx" */),
  "component---src-layouts-legacy-page-template-2-jsx": () => import("./../../../src/layouts/legacyPageTemplate2.jsx" /* webpackChunkName: "component---src-layouts-legacy-page-template-2-jsx" */),
  "component---src-layouts-legacy-page-template-jsx": () => import("./../../../src/layouts/legacyPageTemplate.jsx" /* webpackChunkName: "component---src-layouts-legacy-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-accounting-accounts-payable-jsx": () => import("./../../../src/pages/accounting/accounts-payable.jsx" /* webpackChunkName: "component---src-pages-accounting-accounts-payable-jsx" */),
  "component---src-pages-accounting-accounts-receivable-jsx": () => import("./../../../src/pages/accounting/accounts-receivable.jsx" /* webpackChunkName: "component---src-pages-accounting-accounts-receivable-jsx" */),
  "component---src-pages-accounting-bank-reconciliation-jsx": () => import("./../../../src/pages/accounting/bank-reconciliation.jsx" /* webpackChunkName: "component---src-pages-accounting-bank-reconciliation-jsx" */),
  "component---src-pages-accounting-compliance-jsx": () => import("./../../../src/pages/accounting/compliance.jsx" /* webpackChunkName: "component---src-pages-accounting-compliance-jsx" */),
  "component---src-pages-accounting-financial-year-closing-jsx": () => import("./../../../src/pages/accounting/financial-year-closing.jsx" /* webpackChunkName: "component---src-pages-accounting-financial-year-closing-jsx" */),
  "component---src-pages-accounting-fixed-asset-accounting-jsx": () => import("./../../../src/pages/accounting/fixed-asset-accounting.jsx" /* webpackChunkName: "component---src-pages-accounting-fixed-asset-accounting-jsx" */),
  "component---src-pages-accounting-general-ledger-jsx": () => import("./../../../src/pages/accounting/general-ledger.jsx" /* webpackChunkName: "component---src-pages-accounting-general-ledger-jsx" */),
  "component---src-pages-accounting-index-jsx": () => import("./../../../src/pages/accounting/index.jsx" /* webpackChunkName: "component---src-pages-accounting-index-jsx" */),
  "component---src-pages-accounting-journal-entries-jsx": () => import("./../../../src/pages/accounting/journal-entries.jsx" /* webpackChunkName: "component---src-pages-accounting-journal-entries-jsx" */),
  "component---src-pages-accounting-multi-currency-accounting-jsx": () => import("./../../../src/pages/accounting/multi-currency-accounting.jsx" /* webpackChunkName: "component---src-pages-accounting-multi-currency-accounting-jsx" */),
  "component---src-pages-accounting-software-jsx": () => import("./../../../src/pages/accounting-software.jsx" /* webpackChunkName: "component---src-pages-accounting-software-jsx" */),
  "component---src-pages-affiliate-jsx": () => import("./../../../src/pages/affiliate.jsx" /* webpackChunkName: "component---src-pages-affiliate-jsx" */),
  "component---src-pages-ai-jsx": () => import("./../../../src/pages/ai.jsx" /* webpackChunkName: "component---src-pages-ai-jsx" */),
  "component---src-pages-benefits-of-cloud-erp-jsx": () => import("./../../../src/pages/benefits-of-cloud-erp.jsx" /* webpackChunkName: "component---src-pages-benefits-of-cloud-erp-jsx" */),
  "component---src-pages-billing-software-jsx": () => import("./../../../src/pages/billing-software.jsx" /* webpackChunkName: "component---src-pages-billing-software-jsx" */),
  "component---src-pages-book-a-demo-jsx": () => import("./../../../src/pages/book-a-demo.jsx" /* webpackChunkName: "component---src-pages-book-a-demo-jsx" */),
  "component---src-pages-bookkeeper-jsx": () => import("./../../../src/pages/bookkeeper.jsx" /* webpackChunkName: "component---src-pages-bookkeeper-jsx" */),
  "component---src-pages-bookkeeping-software-jsx": () => import("./../../../src/pages/bookkeeping-software.jsx" /* webpackChunkName: "component---src-pages-bookkeeping-software-jsx" */),
  "component---src-pages-books-accept-and-receive-payments-with-stripe-jsx": () => import("./../../../src/pages/books/accept-and-receive-payments-with-stripe.jsx" /* webpackChunkName: "component---src-pages-books-accept-and-receive-payments-with-stripe-jsx" */),
  "component---src-pages-books-back-orders-with-deskera-books-jsx": () => import("./../../../src/pages/books/back-orders-with-deskera-books.jsx" /* webpackChunkName: "component---src-pages-books-back-orders-with-deskera-books-jsx" */),
  "component---src-pages-books-bank-connect-and-bank-reconciliation-jsx": () => import("./../../../src/pages/books/bank-connect-and-bank-reconciliation.jsx" /* webpackChunkName: "component---src-pages-books-bank-connect-and-bank-reconciliation-jsx" */),
  "component---src-pages-books-bank-integration-jsx": () => import("./../../../src/pages/books/bank-integration.jsx" /* webpackChunkName: "component---src-pages-books-bank-integration-jsx" */),
  "component---src-pages-books-business-dashboard-jsx": () => import("./../../../src/pages/books/business-dashboard.jsx" /* webpackChunkName: "component---src-pages-books-business-dashboard-jsx" */),
  "component---src-pages-books-buying-and-purchase-orders-jsx": () => import("./../../../src/pages/books/buying-and-purchase-orders.jsx" /* webpackChunkName: "component---src-pages-books-buying-and-purchase-orders-jsx" */),
  "component---src-pages-books-chart-of-accounts-with-deskera-books-jsx": () => import("./../../../src/pages/books/chart-of-accounts-with-deskera-books.jsx" /* webpackChunkName: "component---src-pages-books-chart-of-accounts-with-deskera-books-jsx" */),
  "component---src-pages-books-contacts-jsx": () => import("./../../../src/pages/books/contacts.jsx" /* webpackChunkName: "component---src-pages-books-contacts-jsx" */),
  "component---src-pages-books-creating-credit-notes-in-deskera-jsx": () => import("./../../../src/pages/books/creating-credit-notes-in-deskera.jsx" /* webpackChunkName: "component---src-pages-books-creating-credit-notes-in-deskera-jsx" */),
  "component---src-pages-books-creating-quotes-jsx": () => import("./../../../src/pages/books/creating-quotes.jsx" /* webpackChunkName: "component---src-pages-books-creating-quotes-jsx" */),
  "component---src-pages-books-custom-fields-in-deskera-jsx": () => import("./../../../src/pages/books/custom-fields-in-deskera.jsx" /* webpackChunkName: "component---src-pages-books-custom-fields-in-deskera-jsx" */),
  "component---src-pages-books-debit-notes-with-deskera-jsx": () => import("./../../../src/pages/books/debit-notes-with-deskera.jsx" /* webpackChunkName: "component---src-pages-books-debit-notes-with-deskera-jsx" */),
  "component---src-pages-books-deposits-with-deskera-jsx": () => import("./../../../src/pages/books/deposits-with-deskera.jsx" /* webpackChunkName: "component---src-pages-books-deposits-with-deskera-jsx" */),
  "component---src-pages-books-dropship-with-deskera-books-jsx": () => import("./../../../src/pages/books/dropship-with-deskera-books.jsx" /* webpackChunkName: "component---src-pages-books-dropship-with-deskera-books-jsx" */),
  "component---src-pages-books-e-invoicing-jsx": () => import("./../../../src/pages/books/e-invoicing.jsx" /* webpackChunkName: "component---src-pages-books-e-invoicing-jsx" */),
  "component---src-pages-books-expense-jsx": () => import("./../../../src/pages/books/expense.jsx" /* webpackChunkName: "component---src-pages-books-expense-jsx" */),
  "component---src-pages-books-general-ledger-jsx": () => import("./../../../src/pages/books/general-ledger.jsx" /* webpackChunkName: "component---src-pages-books-general-ledger-jsx" */),
  "component---src-pages-books-journal-entries-with-deskera-jsx": () => import("./../../../src/pages/books/journal-entries-with-deskera.jsx" /* webpackChunkName: "component---src-pages-books-journal-entries-with-deskera-jsx" */),
  "component---src-pages-books-multi-currency-jsx": () => import("./../../../src/pages/books/multi-currency.jsx" /* webpackChunkName: "component---src-pages-books-multi-currency-jsx" */),
  "component---src-pages-books-online-invoice-payments-jsx": () => import("./../../../src/pages/books/online-invoice-payments.jsx" /* webpackChunkName: "component---src-pages-books-online-invoice-payments-jsx" */),
  "component---src-pages-books-opening-balances-with-deskera-jsx": () => import("./../../../src/pages/books/opening-balances-with-deskera.jsx" /* webpackChunkName: "component---src-pages-books-opening-balances-with-deskera-jsx" */),
  "component---src-pages-books-paying-bills-with-deskera-jsx": () => import("./../../../src/pages/books/paying-bills-with-deskera.jsx" /* webpackChunkName: "component---src-pages-books-paying-bills-with-deskera-jsx" */),
  "component---src-pages-books-pick-pack-ship-jsx": () => import("./../../../src/pages/books/pick-pack-ship.jsx" /* webpackChunkName: "component---src-pages-books-pick-pack-ship-jsx" */),
  "component---src-pages-books-purchase-orders-jsx": () => import("./../../../src/pages/books/purchase-orders.jsx" /* webpackChunkName: "component---src-pages-books-purchase-orders-jsx" */),
  "component---src-pages-books-reports-jsx": () => import("./../../../src/pages/books/reports.jsx" /* webpackChunkName: "component---src-pages-books-reports-jsx" */),
  "component---src-pages-books-tax-jsx": () => import("./../../../src/pages/books/tax.jsx" /* webpackChunkName: "component---src-pages-books-tax-jsx" */),
  "component---src-pages-books-warehouse-management-jsx": () => import("./../../../src/pages/books/warehouse-management.jsx" /* webpackChunkName: "component---src-pages-books-warehouse-management-jsx" */),
  "component---src-pages-business-intelligence-dashboards-jsx": () => import("./../../../src/pages/business-intelligence/dashboards.jsx" /* webpackChunkName: "component---src-pages-business-intelligence-dashboards-jsx" */),
  "component---src-pages-business-intelligence-forecasts-jsx": () => import("./../../../src/pages/business-intelligence/forecasts.jsx" /* webpackChunkName: "component---src-pages-business-intelligence-forecasts-jsx" */),
  "component---src-pages-business-intelligence-index-jsx": () => import("./../../../src/pages/business-intelligence/index.jsx" /* webpackChunkName: "component---src-pages-business-intelligence-index-jsx" */),
  "component---src-pages-business-intelligence-smart-reports-jsx": () => import("./../../../src/pages/business-intelligence/smart-reports.jsx" /* webpackChunkName: "component---src-pages-business-intelligence-smart-reports-jsx" */),
  "component---src-pages-business-software-my-jsx": () => import("./../../../src/pages/business-software-my.jsx" /* webpackChunkName: "component---src-pages-business-software-my-jsx" */),
  "component---src-pages-business-software-sg-jsx": () => import("./../../../src/pages/business-software-sg.jsx" /* webpackChunkName: "component---src-pages-business-software-sg-jsx" */),
  "component---src-pages-by-product-scrap-management-index-jsx": () => import("./../../../src/pages/by-product-scrap-management/index.jsx" /* webpackChunkName: "component---src-pages-by-product-scrap-management-index-jsx" */),
  "component---src-pages-calculators-jsx": () => import("./../../../src/pages/calculators.jsx" /* webpackChunkName: "component---src-pages-calculators-jsx" */),
  "component---src-pages-comparison-acumatica-jsx": () => import("./../../../src/pages/comparison/acumatica.jsx" /* webpackChunkName: "component---src-pages-comparison-acumatica-jsx" */),
  "component---src-pages-comparison-dynamics-365-jsx": () => import("./../../../src/pages/comparison/dynamics365.jsx" /* webpackChunkName: "component---src-pages-comparison-dynamics-365-jsx" */),
  "component---src-pages-comparison-epicor-jsx": () => import("./../../../src/pages/comparison/epicor.jsx" /* webpackChunkName: "component---src-pages-comparison-epicor-jsx" */),
  "component---src-pages-comparison-fishbowl-jsx": () => import("./../../../src/pages/comparison/fishbowl.jsx" /* webpackChunkName: "component---src-pages-comparison-fishbowl-jsx" */),
  "component---src-pages-comparison-infor-jsx": () => import("./../../../src/pages/comparison/infor.jsx" /* webpackChunkName: "component---src-pages-comparison-infor-jsx" */),
  "component---src-pages-comparison-jobboss-jsx": () => import("./../../../src/pages/comparison/jobboss.jsx" /* webpackChunkName: "component---src-pages-comparison-jobboss-jsx" */),
  "component---src-pages-comparison-jsx": () => import("./../../../src/pages/comparison.jsx" /* webpackChunkName: "component---src-pages-comparison-jsx" */),
  "component---src-pages-comparison-katana-jsx": () => import("./../../../src/pages/comparison/katana.jsx" /* webpackChunkName: "component---src-pages-comparison-katana-jsx" */),
  "component---src-pages-comparison-netsuite-jsx": () => import("./../../../src/pages/comparison/netsuite.jsx" /* webpackChunkName: "component---src-pages-comparison-netsuite-jsx" */),
  "component---src-pages-comparison-odoo-jsx": () => import("./../../../src/pages/comparison/odoo.jsx" /* webpackChunkName: "component---src-pages-comparison-odoo-jsx" */),
  "component---src-pages-comparison-quickbooks-jsx": () => import("./../../../src/pages/comparison/quickbooks.jsx" /* webpackChunkName: "component---src-pages-comparison-quickbooks-jsx" */),
  "component---src-pages-comparison-sage-jsx": () => import("./../../../src/pages/comparison/sage.jsx" /* webpackChunkName: "component---src-pages-comparison-sage-jsx" */),
  "component---src-pages-comparison-sap-business-one-jsx": () => import("./../../../src/pages/comparison/sap-business-one.jsx" /* webpackChunkName: "component---src-pages-comparison-sap-business-one-jsx" */),
  "component---src-pages-competitors-deskera-vs-acumatica-jsx": () => import("./../../../src/pages/competitors/deskera-vs-acumatica.jsx" /* webpackChunkName: "component---src-pages-competitors-deskera-vs-acumatica-jsx" */),
  "component---src-pages-competitors-deskera-vs-d-365-jsx": () => import("./../../../src/pages/competitors/deskera-vs-d365.jsx" /* webpackChunkName: "component---src-pages-competitors-deskera-vs-d-365-jsx" */),
  "component---src-pages-competitors-deskera-vs-epicor-jsx": () => import("./../../../src/pages/competitors/deskera-vs-epicor.jsx" /* webpackChunkName: "component---src-pages-competitors-deskera-vs-epicor-jsx" */),
  "component---src-pages-competitors-deskera-vs-infor-jsx": () => import("./../../../src/pages/competitors/deskera-vs-infor.jsx" /* webpackChunkName: "component---src-pages-competitors-deskera-vs-infor-jsx" */),
  "component---src-pages-competitors-deskera-vs-netsuite-jsx": () => import("./../../../src/pages/competitors/deskera-vs-netsuite.jsx" /* webpackChunkName: "component---src-pages-competitors-deskera-vs-netsuite-jsx" */),
  "component---src-pages-competitors-deskera-vs-odoo-jsx": () => import("./../../../src/pages/competitors/deskera-vs-odoo.jsx" /* webpackChunkName: "component---src-pages-competitors-deskera-vs-odoo-jsx" */),
  "component---src-pages-competitors-deskera-vs-sage-intacct-jsx": () => import("./../../../src/pages/competitors/deskera-vs-sage-intacct.jsx" /* webpackChunkName: "component---src-pages-competitors-deskera-vs-sage-intacct-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contract-manufacturing-index-jsx": () => import("./../../../src/pages/contract-manufacturing/index.jsx" /* webpackChunkName: "component---src-pages-contract-manufacturing-index-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-crm-account-management-jsx": () => import("./../../../src/pages/crm/account-management.jsx" /* webpackChunkName: "component---src-pages-crm-account-management-jsx" */),
  "component---src-pages-crm-activity-management-jsx": () => import("./../../../src/pages/crm/activity-management.jsx" /* webpackChunkName: "component---src-pages-crm-activity-management-jsx" */),
  "component---src-pages-crm-automation-sales-marketing-jsx": () => import("./../../../src/pages/crm/automation-sales-marketing.jsx" /* webpackChunkName: "component---src-pages-crm-automation-sales-marketing-jsx" */),
  "component---src-pages-crm-build-landing-pages-jsx": () => import("./../../../src/pages/crm/build-landing-pages.jsx" /* webpackChunkName: "component---src-pages-crm-build-landing-pages-jsx" */),
  "component---src-pages-crm-contact-management-jsx": () => import("./../../../src/pages/crm/contact-management.jsx" /* webpackChunkName: "component---src-pages-crm-contact-management-jsx" */),
  "component---src-pages-crm-contact-segmentation-jsx": () => import("./../../../src/pages/crm/contact-segmentation.jsx" /* webpackChunkName: "component---src-pages-crm-contact-segmentation-jsx" */),
  "component---src-pages-crm-conversion-funnels-jsx": () => import("./../../../src/pages/crm/conversion-funnels.jsx" /* webpackChunkName: "component---src-pages-crm-conversion-funnels-jsx" */),
  "component---src-pages-crm-customer-relationship-management-contacts-jsx": () => import("./../../../src/pages/crm/customer-relationship-management-contacts.jsx" /* webpackChunkName: "component---src-pages-crm-customer-relationship-management-contacts-jsx" */),
  "component---src-pages-crm-deals-with-deskera-sales-jsx": () => import("./../../../src/pages/crm/deals-with-deskera-sales.jsx" /* webpackChunkName: "component---src-pages-crm-deals-with-deskera-sales-jsx" */),
  "component---src-pages-crm-drip-email-sequences-jsx": () => import("./../../../src/pages/crm/drip-email-sequences.jsx" /* webpackChunkName: "component---src-pages-crm-drip-email-sequences-jsx" */),
  "component---src-pages-crm-email-campaign-jsx": () => import("./../../../src/pages/crm/email-campaign.jsx" /* webpackChunkName: "component---src-pages-crm-email-campaign-jsx" */),
  "component---src-pages-crm-email-campaigns-jsx": () => import("./../../../src/pages/crm/email-campaigns.jsx" /* webpackChunkName: "component---src-pages-crm-email-campaigns-jsx" */),
  "component---src-pages-crm-helpdesk-jsx": () => import("./../../../src/pages/crm/helpdesk.jsx" /* webpackChunkName: "component---src-pages-crm-helpdesk-jsx" */),
  "component---src-pages-crm-index-jsx": () => import("./../../../src/pages/crm/index.jsx" /* webpackChunkName: "component---src-pages-crm-index-jsx" */),
  "component---src-pages-crm-jsx": () => import("./../../../src/pages/crm.jsx" /* webpackChunkName: "component---src-pages-crm-jsx" */),
  "component---src-pages-crm-landing-pages-jsx": () => import("./../../../src/pages/crm/landing-pages.jsx" /* webpackChunkName: "component---src-pages-crm-landing-pages-jsx" */),
  "component---src-pages-crm-lead-forms-jsx": () => import("./../../../src/pages/crm/lead-forms.jsx" /* webpackChunkName: "component---src-pages-crm-lead-forms-jsx" */),
  "component---src-pages-crm-marketing-forms-jsx": () => import("./../../../src/pages/crm/marketing-forms.jsx" /* webpackChunkName: "component---src-pages-crm-marketing-forms-jsx" */),
  "component---src-pages-crm-opportunity-management-jsx": () => import("./../../../src/pages/crm/opportunity-management.jsx" /* webpackChunkName: "component---src-pages-crm-opportunity-management-jsx" */),
  "component---src-pages-crm-plus-jsx": () => import("./../../../src/pages/crm-plus.jsx" /* webpackChunkName: "component---src-pages-crm-plus-jsx" */),
  "component---src-pages-crm-rule-based-automations-jsx": () => import("./../../../src/pages/crm/rule-based-automations.jsx" /* webpackChunkName: "component---src-pages-crm-rule-based-automations-jsx" */),
  "component---src-pages-crm-sales-pipeline-jsx": () => import("./../../../src/pages/crm/sales-pipeline.jsx" /* webpackChunkName: "component---src-pages-crm-sales-pipeline-jsx" */),
  "component---src-pages-crm-sales-pipeline-management-jsx": () => import("./../../../src/pages/crm/sales-pipeline-management.jsx" /* webpackChunkName: "component---src-pages-crm-sales-pipeline-management-jsx" */),
  "component---src-pages-crm-user-role-management-with-deskera-jsx": () => import("./../../../src/pages/crm/user-role-management-with-deskera.jsx" /* webpackChunkName: "component---src-pages-crm-user-role-management-with-deskera-jsx" */),
  "component---src-pages-crm-web-to-lead-form-jsx": () => import("./../../../src/pages/crm/web-to-lead-form.jsx" /* webpackChunkName: "component---src-pages-crm-web-to-lead-form-jsx" */),
  "component---src-pages-customer-service-chat-widget-jsx": () => import("./../../../src/pages/customer-service/chat-widget.jsx" /* webpackChunkName: "component---src-pages-customer-service-chat-widget-jsx" */),
  "component---src-pages-customer-service-email-helpdesk-jsx": () => import("./../../../src/pages/customer-service/email-helpdesk.jsx" /* webpackChunkName: "component---src-pages-customer-service-email-helpdesk-jsx" */),
  "component---src-pages-customer-service-index-jsx": () => import("./../../../src/pages/customer-service/index.jsx" /* webpackChunkName: "component---src-pages-customer-service-index-jsx" */),
  "component---src-pages-customer-service-support-tickets-jsx": () => import("./../../../src/pages/customer-service/support-tickets.jsx" /* webpackChunkName: "component---src-pages-customer-service-support-tickets-jsx" */),
  "component---src-pages-data-protection-policy-jsx": () => import("./../../../src/pages/data-protection-policy.jsx" /* webpackChunkName: "component---src-pages-data-protection-policy-jsx" */),
  "component---src-pages-demand-forecasting-index-jsx": () => import("./../../../src/pages/demand-forecasting/index.jsx" /* webpackChunkName: "component---src-pages-demand-forecasting-index-jsx" */),
  "component---src-pages-deskera-mobile-jsx": () => import("./../../../src/pages/deskera-mobile.jsx" /* webpackChunkName: "component---src-pages-deskera-mobile-jsx" */),
  "component---src-pages-enterprise-jsx": () => import("./../../../src/pages/enterprise.jsx" /* webpackChunkName: "component---src-pages-enterprise-jsx" */),
  "component---src-pages-erp-checklist-management-jsx": () => import("./../../../src/pages/erp/checklist-management.jsx" /* webpackChunkName: "component---src-pages-erp-checklist-management-jsx" */),
  "component---src-pages-erp-financial-reports-jsx": () => import("./../../../src/pages/erp/financial-reports.jsx" /* webpackChunkName: "component---src-pages-erp-financial-reports-jsx" */),
  "component---src-pages-erp-for-aviation-jsx": () => import("./../../../src/pages/erp-for-aviation.jsx" /* webpackChunkName: "component---src-pages-erp-for-aviation-jsx" */),
  "component---src-pages-erp-for-services-jsx": () => import("./../../../src/pages/erp-for-services.jsx" /* webpackChunkName: "component---src-pages-erp-for-services-jsx" */),
  "component---src-pages-erp-index-jsx": () => import("./../../../src/pages/erp/index.jsx" /* webpackChunkName: "component---src-pages-erp-index-jsx" */),
  "component---src-pages-erp-item-master-management-jsx": () => import("./../../../src/pages/erp/item-master-management.jsx" /* webpackChunkName: "component---src-pages-erp-item-master-management-jsx" */),
  "component---src-pages-erp-malaysian-gst-jsx": () => import("./../../../src/pages/erp/malaysian-gst.jsx" /* webpackChunkName: "component---src-pages-erp-malaysian-gst-jsx" */),
  "component---src-pages-erp-product-management-jsx": () => import("./../../../src/pages/erp/product-management.jsx" /* webpackChunkName: "component---src-pages-erp-product-management-jsx" */),
  "component---src-pages-erp-purchase-management-jsx": () => import("./../../../src/pages/erp/purchase-management.jsx" /* webpackChunkName: "component---src-pages-erp-purchase-management-jsx" */),
  "component---src-pages-erp-singapore-gst-jsx": () => import("./../../../src/pages/erp/singapore-gst.jsx" /* webpackChunkName: "component---src-pages-erp-singapore-gst-jsx" */),
  "component---src-pages-erp-statutory-compliance-jsx": () => import("./../../../src/pages/erp/statutory-compliance.jsx" /* webpackChunkName: "component---src-pages-erp-statutory-compliance-jsx" */),
  "component---src-pages-erp-stock-management-jsx": () => import("./../../../src/pages/erp/stock-management.jsx" /* webpackChunkName: "component---src-pages-erp-stock-management-jsx" */),
  "component---src-pages-erp-vendor-management-jsx": () => import("./../../../src/pages/erp/vendor-management.jsx" /* webpackChunkName: "component---src-pages-erp-vendor-management-jsx" */),
  "component---src-pages-financial-management-approval-workflows-jsx": () => import("./../../../src/pages/financial-management/approval-workflows.jsx" /* webpackChunkName: "component---src-pages-financial-management-approval-workflows-jsx" */),
  "component---src-pages-financial-management-budget-forecasting-jsx": () => import("./../../../src/pages/financial-management/budget-forecasting.jsx" /* webpackChunkName: "component---src-pages-financial-management-budget-forecasting-jsx" */),
  "component---src-pages-financial-management-financial-reporting-jsx": () => import("./../../../src/pages/financial-management/financial-reporting.jsx" /* webpackChunkName: "component---src-pages-financial-management-financial-reporting-jsx" */),
  "component---src-pages-financial-management-index-jsx": () => import("./../../../src/pages/financial-management/index.jsx" /* webpackChunkName: "component---src-pages-financial-management-index-jsx" */),
  "component---src-pages-financial-management-multi-book-accounting-jsx": () => import("./../../../src/pages/financial-management/multi-book-accounting.jsx" /* webpackChunkName: "component---src-pages-financial-management-multi-book-accounting-jsx" */),
  "component---src-pages-financial-management-multi-entity-consolidation-jsx": () => import("./../../../src/pages/financial-management/multi-entity-consolidation.jsx" /* webpackChunkName: "component---src-pages-financial-management-multi-entity-consolidation-jsx" */),
  "component---src-pages-financial-management-revaluation-jsx": () => import("./../../../src/pages/financial-management/revaluation.jsx" /* webpackChunkName: "component---src-pages-financial-management-revaluation-jsx" */),
  "component---src-pages-financial-management-revenue-recognition-jsx": () => import("./../../../src/pages/financial-management/revenue-recognition.jsx" /* webpackChunkName: "component---src-pages-financial-management-revenue-recognition-jsx" */),
  "component---src-pages-financial-management-role-based-access-jsx": () => import("./../../../src/pages/financial-management/role-based-access.jsx" /* webpackChunkName: "component---src-pages-financial-management-role-based-access-jsx" */),
  "component---src-pages-footwear-manufacturing-jsx": () => import("./../../../src/pages/footwear-manufacturing.jsx" /* webpackChunkName: "component---src-pages-footwear-manufacturing-jsx" */),
  "component---src-pages-growth-jsx": () => import("./../../../src/pages/growth.jsx" /* webpackChunkName: "component---src-pages-growth-jsx" */),
  "component---src-pages-hris-jsx": () => import("./../../../src/pages/hris.jsx" /* webpackChunkName: "component---src-pages-hris-jsx" */),
  "component---src-pages-hrms-attendance-and-leave-management-jsx": () => import("./../../../src/pages/hrms/attendance-and-leave-management.jsx" /* webpackChunkName: "component---src-pages-hrms-attendance-and-leave-management-jsx" */),
  "component---src-pages-hrms-claims-expense-management-jsx": () => import("./../../../src/pages/hrms/claims-expense-management.jsx" /* webpackChunkName: "component---src-pages-hrms-claims-expense-management-jsx" */),
  "component---src-pages-hrms-cloud-jsx": () => import("./../../../src/pages/hrms-cloud.jsx" /* webpackChunkName: "component---src-pages-hrms-cloud-jsx" */),
  "component---src-pages-hrms-employee-database-management-jsx": () => import("./../../../src/pages/hrms/employee-database-management.jsx" /* webpackChunkName: "component---src-pages-hrms-employee-database-management-jsx" */),
  "component---src-pages-hrms-index-jsx": () => import("./../../../src/pages/hrms/index.jsx" /* webpackChunkName: "component---src-pages-hrms-index-jsx" */),
  "component---src-pages-hrms-payroll-management-jsx": () => import("./../../../src/pages/hrms/payroll-management.jsx" /* webpackChunkName: "component---src-pages-hrms-payroll-management-jsx" */),
  "component---src-pages-hrms-performance-management-jsx": () => import("./../../../src/pages/hrms/performance-management.jsx" /* webpackChunkName: "component---src-pages-hrms-performance-management-jsx" */),
  "component---src-pages-hrms-recruitment-management-jsx": () => import("./../../../src/pages/hrms/recruitment-management.jsx" /* webpackChunkName: "component---src-pages-hrms-recruitment-management-jsx" */),
  "component---src-pages-hrms-shift-management-jsx": () => import("./../../../src/pages/hrms/shift-management.jsx" /* webpackChunkName: "component---src-pages-hrms-shift-management-jsx" */),
  "component---src-pages-hrms-timesheet-tracking-jsx": () => import("./../../../src/pages/hrms/timesheet-tracking.jsx" /* webpackChunkName: "component---src-pages-hrms-timesheet-tracking-jsx" */),
  "component---src-pages-in-accounting-software-jsx": () => import("./../../../src/pages/in/accounting-software.jsx" /* webpackChunkName: "component---src-pages-in-accounting-software-jsx" */),
  "component---src-pages-in-gst-benefits-and-impact-on-indian-economy-jsx": () => import("./../../../src/pages/in/gst-benefits-and-impact-on-indian-economy.jsx" /* webpackChunkName: "component---src-pages-in-gst-benefits-and-impact-on-indian-economy-jsx" */),
  "component---src-pages-in-gst-jsx": () => import("./../../../src/pages/in/gst.jsx" /* webpackChunkName: "component---src-pages-in-gst-jsx" */),
  "component---src-pages-in-index-jsx": () => import("./../../../src/pages/in/index.jsx" /* webpackChunkName: "component---src-pages-in-index-jsx" */),
  "component---src-pages-in-people-jsx": () => import("./../../../src/pages/in/people.jsx" /* webpackChunkName: "component---src-pages-in-people-jsx" */),
  "component---src-pages-in-pricing-index-jsx": () => import("./../../../src/pages/in/pricing/index.jsx" /* webpackChunkName: "component---src-pages-in-pricing-index-jsx" */),
  "component---src-pages-in-woocommerce-deskera-jsx": () => import("./../../../src/pages/in/woocommerce-deskera.jsx" /* webpackChunkName: "component---src-pages-in-woocommerce-deskera-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-advertising-and-digital-companies-jsx": () => import("./../../../src/pages/industries/advertising-and-digital-companies.jsx" /* webpackChunkName: "component---src-pages-industries-advertising-and-digital-companies-jsx" */),
  "component---src-pages-industries-aerospace-and-defence-jsx": () => import("./../../../src/pages/industries/aerospace-and-defence.jsx" /* webpackChunkName: "component---src-pages-industries-aerospace-and-defence-jsx" */),
  "component---src-pages-industries-apparel-and-footwear-companies-jsx": () => import("./../../../src/pages/industries/apparel-and-footwear-companies.jsx" /* webpackChunkName: "component---src-pages-industries-apparel-and-footwear-companies-jsx" */),
  "component---src-pages-industries-apparel-clothing-textile-jsx": () => import("./../../../src/pages/industries/apparel-clothing-textile.jsx" /* webpackChunkName: "component---src-pages-industries-apparel-clothing-textile-jsx" */),
  "component---src-pages-industries-automobile-manufacturers-and-services-jsx": () => import("./../../../src/pages/industries/automobile-manufacturers-and-services.jsx" /* webpackChunkName: "component---src-pages-industries-automobile-manufacturers-and-services-jsx" */),
  "component---src-pages-industries-automotive-manufacturing-jsx": () => import("./../../../src/pages/industries/automotive-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-automotive-manufacturing-jsx" */),
  "component---src-pages-industries-computer-and-electronics-manufacturers-jsx": () => import("./../../../src/pages/industries/computer-and-electronics-manufacturers.jsx" /* webpackChunkName: "component---src-pages-industries-computer-and-electronics-manufacturers-jsx" */),
  "component---src-pages-industries-construction-and-engineering-jsx": () => import("./../../../src/pages/industries/construction-and-engineering.jsx" /* webpackChunkName: "component---src-pages-industries-construction-and-engineering-jsx" */),
  "component---src-pages-industries-consulting-firms-jsx": () => import("./../../../src/pages/industries/consulting-firms.jsx" /* webpackChunkName: "component---src-pages-industries-consulting-firms-jsx" */),
  "component---src-pages-industries-ecommerce-jsx": () => import("./../../../src/pages/industries/ecommerce.jsx" /* webpackChunkName: "component---src-pages-industries-ecommerce-jsx" */),
  "component---src-pages-industries-education-institutions-jsx": () => import("./../../../src/pages/industries/education-institutions.jsx" /* webpackChunkName: "component---src-pages-industries-education-institutions-jsx" */),
  "component---src-pages-industries-energy-companies-jsx": () => import("./../../../src/pages/industries/energy-companies.jsx" /* webpackChunkName: "component---src-pages-industries-energy-companies-jsx" */),
  "component---src-pages-industries-financial-services-firms-jsx": () => import("./../../../src/pages/industries/financial-services-firms.jsx" /* webpackChunkName: "component---src-pages-industries-financial-services-firms-jsx" */),
  "component---src-pages-industries-food-and-beverage-manufacturers-jsx": () => import("./../../../src/pages/industries/food-and-beverage-manufacturers.jsx" /* webpackChunkName: "component---src-pages-industries-food-and-beverage-manufacturers-jsx" */),
  "component---src-pages-industries-food-manufacturing-erp-jsx": () => import("./../../../src/pages/industries/food-manufacturing-erp.jsx" /* webpackChunkName: "component---src-pages-industries-food-manufacturing-erp-jsx" */),
  "component---src-pages-industries-furniture-manufacturing-jsx": () => import("./../../../src/pages/industries/furniture-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-furniture-manufacturing-jsx" */),
  "component---src-pages-industries-health-and-beauty-companies-jsx": () => import("./../../../src/pages/industries/health-and-beauty-companies.jsx" /* webpackChunkName: "component---src-pages-industries-health-and-beauty-companies-jsx" */),
  "component---src-pages-industries-healthcare-jsx": () => import("./../../../src/pages/industries/healthcare.jsx" /* webpackChunkName: "component---src-pages-industries-healthcare-jsx" */),
  "component---src-pages-industries-high-tech-and-electronics-jsx": () => import("./../../../src/pages/industries/high-tech-and-electronics.jsx" /* webpackChunkName: "component---src-pages-industries-high-tech-and-electronics-jsx" */),
  "component---src-pages-industries-industrial-machinery-equipment-manufacturing-jsx": () => import("./../../../src/pages/industries/industrial-machinery-equipment-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-industrial-machinery-equipment-manufacturing-jsx" */),
  "component---src-pages-industries-industrial-manufacturing-jsx": () => import("./../../../src/pages/industries/industrial-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-industrial-manufacturing-jsx" */),
  "component---src-pages-industries-it-services-jsx": () => import("./../../../src/pages/industries/it-services.jsx" /* webpackChunkName: "component---src-pages-industries-it-services-jsx" */),
  "component---src-pages-industries-jsx": () => import("./../../../src/pages/industries.jsx" /* webpackChunkName: "component---src-pages-industries-jsx" */),
  "component---src-pages-industries-manufacturing-companies-jsx": () => import("./../../../src/pages/industries/manufacturing-companies.jsx" /* webpackChunkName: "component---src-pages-industries-manufacturing-companies-jsx" */),
  "component---src-pages-industries-manufacturing-jsx": () => import("./../../../src/pages/industries/manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-manufacturing-jsx" */),
  "component---src-pages-industries-marketing-agencies-jsx": () => import("./../../../src/pages/industries/marketing-agencies.jsx" /* webpackChunkName: "component---src-pages-industries-marketing-agencies-jsx" */),
  "component---src-pages-industries-media-and-publishing-companies-jsx": () => import("./../../../src/pages/industries/media-and-publishing-companies.jsx" /* webpackChunkName: "component---src-pages-industries-media-and-publishing-companies-jsx" */),
  "component---src-pages-industries-medical-device-manufacturing-jsx": () => import("./../../../src/pages/industries/medical-device-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-medical-device-manufacturing-jsx" */),
  "component---src-pages-industries-medical-supplies-manufacturers-jsx": () => import("./../../../src/pages/industries/medical-supplies-manufacturers.jsx" /* webpackChunkName: "component---src-pages-industries-medical-supplies-manufacturers-jsx" */),
  "component---src-pages-industries-metal-fabrication-jsx": () => import("./../../../src/pages/industries/metal-fabrication.jsx" /* webpackChunkName: "component---src-pages-industries-metal-fabrication-jsx" */),
  "component---src-pages-industries-nonprofit-institutions-jsx": () => import("./../../../src/pages/industries/nonprofit-institutions.jsx" /* webpackChunkName: "component---src-pages-industries-nonprofit-institutions-jsx" */),
  "component---src-pages-industries-plastic-manufacturing-jsx": () => import("./../../../src/pages/industries/plastic-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-plastic-manufacturing-jsx" */),
  "component---src-pages-industries-professional-services-jsx": () => import("./../../../src/pages/industries/professional-services.jsx" /* webpackChunkName: "component---src-pages-industries-professional-services-jsx" */),
  "component---src-pages-industries-screw-shop-manufacturing-jsx": () => import("./../../../src/pages/industries/screw-shop-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-screw-shop-manufacturing-jsx" */),
  "component---src-pages-industries-semiconductor-manufacturers-jsx": () => import("./../../../src/pages/industries/semiconductor-manufacturers.jsx" /* webpackChunkName: "component---src-pages-industries-semiconductor-manufacturers-jsx" */),
  "component---src-pages-industries-software-and-technology-companies-jsx": () => import("./../../../src/pages/industries/software-and-technology-companies.jsx" /* webpackChunkName: "component---src-pages-industries-software-and-technology-companies-jsx" */),
  "component---src-pages-industries-solar-energy-systems-and-services-jsx": () => import("./../../../src/pages/industries/solar-energy-systems-and-services.jsx" /* webpackChunkName: "component---src-pages-industries-solar-energy-systems-and-services-jsx" */),
  "component---src-pages-industries-spring-shop-manufacturing-jsx": () => import("./../../../src/pages/industries/spring-shop-manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-spring-shop-manufacturing-jsx" */),
  "component---src-pages-industries-transportation-and-logistics-companies-jsx": () => import("./../../../src/pages/industries/transportation-and-logistics-companies.jsx" /* webpackChunkName: "component---src-pages-industries-transportation-and-logistics-companies-jsx" */),
  "component---src-pages-industries-wholesale-distribution-companies-jsx": () => import("./../../../src/pages/industries/wholesale-distribution-companies.jsx" /* webpackChunkName: "component---src-pages-industries-wholesale-distribution-companies-jsx" */),
  "component---src-pages-inventory-management-barcode-generation-and-scanning-jsx": () => import("./../../../src/pages/inventory-management/barcode-generation-and-scanning.jsx" /* webpackChunkName: "component---src-pages-inventory-management-barcode-generation-and-scanning-jsx" */),
  "component---src-pages-inventory-management-bill-of-materials-jsx": () => import("./../../../src/pages/inventory-management/bill-of-materials.jsx" /* webpackChunkName: "component---src-pages-inventory-management-bill-of-materials-jsx" */),
  "component---src-pages-inventory-management-cycle-count-and-stock-adjustments-jsx": () => import("./../../../src/pages/inventory-management/cycle-count-and-stock-adjustments.jsx" /* webpackChunkName: "component---src-pages-inventory-management-cycle-count-and-stock-adjustments-jsx" */),
  "component---src-pages-inventory-management-fulfillment-tracking-jsx": () => import("./../../../src/pages/inventory-management/fulfillment-tracking.jsx" /* webpackChunkName: "component---src-pages-inventory-management-fulfillment-tracking-jsx" */),
  "component---src-pages-inventory-management-index-jsx": () => import("./../../../src/pages/inventory-management/index.jsx" /* webpackChunkName: "component---src-pages-inventory-management-index-jsx" */),
  "component---src-pages-inventory-management-inventory-analysis-jsx": () => import("./../../../src/pages/inventory-management/inventory-analysis.jsx" /* webpackChunkName: "component---src-pages-inventory-management-inventory-analysis-jsx" */),
  "component---src-pages-inventory-management-inventory-automations-jsx": () => import("./../../../src/pages/inventory-management/inventory-automations.jsx" /* webpackChunkName: "component---src-pages-inventory-management-inventory-automations-jsx" */),
  "component---src-pages-inventory-management-inventory-pricing-jsx": () => import("./../../../src/pages/inventory-management/inventory-pricing.jsx" /* webpackChunkName: "component---src-pages-inventory-management-inventory-pricing-jsx" */),
  "component---src-pages-inventory-management-inventory-replenishment-jsx": () => import("./../../../src/pages/inventory-management/inventory-replenishment.jsx" /* webpackChunkName: "component---src-pages-inventory-management-inventory-replenishment-jsx" */),
  "component---src-pages-inventory-management-inventory-reporting-jsx": () => import("./../../../src/pages/inventory-management/inventory-reporting.jsx" /* webpackChunkName: "component---src-pages-inventory-management-inventory-reporting-jsx" */),
  "component---src-pages-inventory-management-landed-cost-of-inventory-jsx": () => import("./../../../src/pages/inventory-management/landed-cost-of-inventory.jsx" /* webpackChunkName: "component---src-pages-inventory-management-landed-cost-of-inventory-jsx" */),
  "component---src-pages-inventory-management-lot-batch-and-serial-tracking-jsx": () => import("./../../../src/pages/inventory-management/lot-batch-and-serial-tracking.jsx" /* webpackChunkName: "component---src-pages-inventory-management-lot-batch-and-serial-tracking-jsx" */),
  "component---src-pages-inventory-management-multi-warehouse-inventory-tracking-jsx": () => import("./../../../src/pages/inventory-management/multi-warehouse-inventory-tracking.jsx" /* webpackChunkName: "component---src-pages-inventory-management-multi-warehouse-inventory-tracking-jsx" */),
  "component---src-pages-inventory-management-software-jsx": () => import("./../../../src/pages/inventory-management-software.jsx" /* webpackChunkName: "component---src-pages-inventory-management-software-jsx" */),
  "component---src-pages-inventory-management-uom-schema-jsx": () => import("./../../../src/pages/inventory-management/uom-schema.jsx" /* webpackChunkName: "component---src-pages-inventory-management-uom-schema-jsx" */),
  "component---src-pages-invoice-jsx": () => import("./../../../src/pages/invoice.jsx" /* webpackChunkName: "component---src-pages-invoice-jsx" */),
  "component---src-pages-lms-mobile-lms-jsx": () => import("./../../../src/pages/lms/mobile-lms.jsx" /* webpackChunkName: "component---src-pages-lms-mobile-lms-jsx" */),
  "component---src-pages-machine-management-index-jsx": () => import("./../../../src/pages/machine-management/index.jsx" /* webpackChunkName: "component---src-pages-machine-management-index-jsx" */),
  "component---src-pages-manufacturing-accounting-jsx": () => import("./../../../src/pages/manufacturing-accounting.jsx" /* webpackChunkName: "component---src-pages-manufacturing-accounting-jsx" */),
  "component---src-pages-manufacturing-erp-software-jsx": () => import("./../../../src/pages/manufacturing-erp-software.jsx" /* webpackChunkName: "component---src-pages-manufacturing-erp-software-jsx" */),
  "component---src-pages-manufacturing-inventory-jsx": () => import("./../../../src/pages/manufacturing-inventory.jsx" /* webpackChunkName: "component---src-pages-manufacturing-inventory-jsx" */),
  "component---src-pages-manufacturing-software-jsx": () => import("./../../../src/pages/manufacturing-software.jsx" /* webpackChunkName: "component---src-pages-manufacturing-software-jsx" */),
  "component---src-pages-material-requirement-planning-index-jsx": () => import("./../../../src/pages/material-requirement-planning/index.jsx" /* webpackChunkName: "component---src-pages-material-requirement-planning-index-jsx" */),
  "component---src-pages-material-resource-planning-jsx": () => import("./../../../src/pages/material-resource-planning.jsx" /* webpackChunkName: "component---src-pages-material-resource-planning-jsx" */),
  "component---src-pages-migration-jsx": () => import("./../../../src/pages/migration.jsx" /* webpackChunkName: "component---src-pages-migration-jsx" */),
  "component---src-pages-mrp-delivery-planning-jsx": () => import("./../../../src/pages/mrp/delivery-planning.jsx" /* webpackChunkName: "component---src-pages-mrp-delivery-planning-jsx" */),
  "component---src-pages-mrp-demand-forecasting-and-planning-jsx": () => import("./../../../src/pages/mrp/demand-forecasting-and-planning.jsx" /* webpackChunkName: "component---src-pages-mrp-demand-forecasting-and-planning-jsx" */),
  "component---src-pages-mrp-index-jsx": () => import("./../../../src/pages/mrp/index.jsx" /* webpackChunkName: "component---src-pages-mrp-index-jsx" */),
  "component---src-pages-mrp-labor-master-jsx": () => import("./../../../src/pages/mrp/labor-master.jsx" /* webpackChunkName: "component---src-pages-mrp-labor-master-jsx" */),
  "component---src-pages-mrp-machine-master-jsx": () => import("./../../../src/pages/mrp/machine-master.jsx" /* webpackChunkName: "component---src-pages-mrp-machine-master-jsx" */),
  "component---src-pages-mrp-master-contract-jsx": () => import("./../../../src/pages/mrp/master-contract.jsx" /* webpackChunkName: "component---src-pages-mrp-master-contract-jsx" */),
  "component---src-pages-mrp-master-production-schedule-jsx": () => import("./../../../src/pages/mrp/master-production-schedule.jsx" /* webpackChunkName: "component---src-pages-mrp-master-production-schedule-jsx" */),
  "component---src-pages-mrp-product-master-jsx": () => import("./../../../src/pages/mrp/product-master.jsx" /* webpackChunkName: "component---src-pages-mrp-product-master-jsx" */),
  "component---src-pages-mrp-quality-control-jsx": () => import("./../../../src/pages/mrp/quality-control.jsx" /* webpackChunkName: "component---src-pages-mrp-quality-control-jsx" */),
  "component---src-pages-mrp-routing-master-jsx": () => import("./../../../src/pages/mrp/routing-master.jsx" /* webpackChunkName: "component---src-pages-mrp-routing-master-jsx" */),
  "component---src-pages-mrp-software-jsx": () => import("./../../../src/pages/mrp-software.jsx" /* webpackChunkName: "component---src-pages-mrp-software-jsx" */),
  "component---src-pages-mrp-work-center-master-jsx": () => import("./../../../src/pages/mrp/work-center-master.jsx" /* webpackChunkName: "component---src-pages-mrp-work-center-master-jsx" */),
  "component---src-pages-mrp-work-order-jsx": () => import("./../../../src/pages/mrp/work-order.jsx" /* webpackChunkName: "component---src-pages-mrp-work-order-jsx" */),
  "component---src-pages-multi-level-bill-of-materials-index-jsx": () => import("./../../../src/pages/multi-level-bill-of-materials/index.jsx" /* webpackChunkName: "component---src-pages-multi-level-bill-of-materials-index-jsx" */),
  "component---src-pages-order-management-dropship-jsx": () => import("./../../../src/pages/order-management/dropship.jsx" /* webpackChunkName: "component---src-pages-order-management-dropship-jsx" */),
  "component---src-pages-order-management-index-jsx": () => import("./../../../src/pages/order-management/index.jsx" /* webpackChunkName: "component---src-pages-order-management-index-jsx" */),
  "component---src-pages-order-management-multi-channel-order-management-jsx": () => import("./../../../src/pages/order-management/multi-channel-order-management.jsx" /* webpackChunkName: "component---src-pages-order-management-multi-channel-order-management-jsx" */),
  "component---src-pages-order-management-order-fulfillment-jsx": () => import("./../../../src/pages/order-management/order-fulfillment.jsx" /* webpackChunkName: "component---src-pages-order-management-order-fulfillment-jsx" */),
  "component---src-pages-order-management-pick-pack-ship-jsx": () => import("./../../../src/pages/order-management/pick-pack-ship.jsx" /* webpackChunkName: "component---src-pages-order-management-pick-pack-ship-jsx" */),
  "component---src-pages-order-management-reserve-stock-jsx": () => import("./../../../src/pages/order-management/reserve-stock.jsx" /* webpackChunkName: "component---src-pages-order-management-reserve-stock-jsx" */),
  "component---src-pages-partner-jsx": () => import("./../../../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */),
  "component---src-pages-payslip-jsx": () => import("./../../../src/pages/payslip.jsx" /* webpackChunkName: "component---src-pages-payslip-jsx" */),
  "component---src-pages-people-attendance-and-leave-jsx": () => import("./../../../src/pages/people/attendance-and-leave.jsx" /* webpackChunkName: "component---src-pages-people-attendance-and-leave-jsx" */),
  "component---src-pages-people-expenses-jsx": () => import("./../../../src/pages/people/expenses.jsx" /* webpackChunkName: "component---src-pages-people-expenses-jsx" */),
  "component---src-pages-people-hiring-automation-jsx": () => import("./../../../src/pages/people/hiring-automation.jsx" /* webpackChunkName: "component---src-pages-people-hiring-automation-jsx" */),
  "component---src-pages-people-jsx": () => import("./../../../src/pages/people.jsx" /* webpackChunkName: "component---src-pages-people-jsx" */),
  "component---src-pages-people-payroll-jsx": () => import("./../../../src/pages/people/payroll.jsx" /* webpackChunkName: "component---src-pages-people-payroll-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("./../../../src/pages/pricing/index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-process-manufacturing-jsx": () => import("./../../../src/pages/process-manufacturing.jsx" /* webpackChunkName: "component---src-pages-process-manufacturing-jsx" */),
  "component---src-pages-procurement-approval-workflows-jsx": () => import("./../../../src/pages/procurement/approval-workflows.jsx" /* webpackChunkName: "component---src-pages-procurement-approval-workflows-jsx" */),
  "component---src-pages-procurement-index-jsx": () => import("./../../../src/pages/procurement/index.jsx" /* webpackChunkName: "component---src-pages-procurement-index-jsx" */),
  "component---src-pages-procurement-purchase-invoices-jsx": () => import("./../../../src/pages/procurement/purchase-invoices.jsx" /* webpackChunkName: "component---src-pages-procurement-purchase-invoices-jsx" */),
  "component---src-pages-procurement-purchase-orders-jsx": () => import("./../../../src/pages/procurement/purchase-orders.jsx" /* webpackChunkName: "component---src-pages-procurement-purchase-orders-jsx" */),
  "component---src-pages-procurement-purchase-requisitions-jsx": () => import("./../../../src/pages/procurement/purchase-requisitions.jsx" /* webpackChunkName: "component---src-pages-procurement-purchase-requisitions-jsx" */),
  "component---src-pages-procurement-rfqs-vendor-quotations-jsx": () => import("./../../../src/pages/procurement/rfqs-vendor-quotations.jsx" /* webpackChunkName: "component---src-pages-procurement-rfqs-vendor-quotations-jsx" */),
  "component---src-pages-production-planning-index-jsx": () => import("./../../../src/pages/production-planning/index.jsx" /* webpackChunkName: "component---src-pages-production-planning-index-jsx" */),
  "component---src-pages-production-reports-index-jsx": () => import("./../../../src/pages/production-reports/index.jsx" /* webpackChunkName: "component---src-pages-production-reports-index-jsx" */),
  "component---src-pages-project-accounting-bill-of-quantities-jsx": () => import("./../../../src/pages/project-accounting/bill-of-quantities.jsx" /* webpackChunkName: "component---src-pages-project-accounting-bill-of-quantities-jsx" */),
  "component---src-pages-project-accounting-index-jsx": () => import("./../../../src/pages/project-accounting/index.jsx" /* webpackChunkName: "component---src-pages-project-accounting-index-jsx" */),
  "component---src-pages-project-accounting-payment-milestones-jsx": () => import("./../../../src/pages/project-accounting/payment-milestones.jsx" /* webpackChunkName: "component---src-pages-project-accounting-payment-milestones-jsx" */),
  "component---src-pages-project-accounting-project-costing-and-p-and-l-jsx": () => import("./../../../src/pages/project-accounting/project-costing-and-p-and-l.jsx" /* webpackChunkName: "component---src-pages-project-accounting-project-costing-and-p-and-l-jsx" */),
  "component---src-pages-project-accounting-project-time-tracking-jsx": () => import("./../../../src/pages/project-accounting/project-time-tracking.jsx" /* webpackChunkName: "component---src-pages-project-accounting-project-time-tracking-jsx" */),
  "component---src-pages-quickbooks-alternative-jsx": () => import("./../../../src/pages/quickbooks-alternative.jsx" /* webpackChunkName: "component---src-pages-quickbooks-alternative-jsx" */),
  "component---src-pages-receipts-jsx": () => import("./../../../src/pages/receipts.jsx" /* webpackChunkName: "component---src-pages-receipts-jsx" */),
  "component---src-pages-report-builder-jsx": () => import("./../../../src/pages/report-builder.jsx" /* webpackChunkName: "component---src-pages-report-builder-jsx" */),
  "component---src-pages-services-implementation-jsx": () => import("./../../../src/pages/services/implementation.jsx" /* webpackChunkName: "component---src-pages-services-implementation-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-signin-jsx": () => import("./../../../src/pages/signin.jsx" /* webpackChunkName: "component---src-pages-signin-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-small-business-manufacturing-jsx": () => import("./../../../src/pages/small-business-manufacturing.jsx" /* webpackChunkName: "component---src-pages-small-business-manufacturing-jsx" */),
  "component---src-pages-solutions-business-jsx": () => import("./../../../src/pages/solutions/business.jsx" /* webpackChunkName: "component---src-pages-solutions-business-jsx" */),
  "component---src-pages-solutions-ceo-jsx": () => import("./../../../src/pages/solutions/ceo.jsx" /* webpackChunkName: "component---src-pages-solutions-ceo-jsx" */),
  "component---src-pages-solutions-cfo-jsx": () => import("./../../../src/pages/solutions/cfo.jsx" /* webpackChunkName: "component---src-pages-solutions-cfo-jsx" */),
  "component---src-pages-solutions-cio-jsx": () => import("./../../../src/pages/solutions/cio.jsx" /* webpackChunkName: "component---src-pages-solutions-cio-jsx" */),
  "component---src-pages-solutions-controller-jsx": () => import("./../../../src/pages/solutions/controller.jsx" /* webpackChunkName: "component---src-pages-solutions-controller-jsx" */),
  "component---src-pages-solutions-finance-jsx": () => import("./../../../src/pages/solutions/finance.jsx" /* webpackChunkName: "component---src-pages-solutions-finance-jsx" */),
  "component---src-pages-solutions-it-jsx": () => import("./../../../src/pages/solutions/it.jsx" /* webpackChunkName: "component---src-pages-solutions-it-jsx" */),
  "component---src-pages-solutions-mid-market-jsx": () => import("./../../../src/pages/solutions/mid-market.jsx" /* webpackChunkName: "component---src-pages-solutions-mid-market-jsx" */),
  "component---src-pages-solutions-operations-jsx": () => import("./../../../src/pages/solutions/operations.jsx" /* webpackChunkName: "component---src-pages-solutions-operations-jsx" */),
  "component---src-pages-solutions-roles-jsx": () => import("./../../../src/pages/solutions/roles.jsx" /* webpackChunkName: "component---src-pages-solutions-roles-jsx" */),
  "component---src-pages-solutions-sales-and-marketing-jsx": () => import("./../../../src/pages/solutions/sales-and-marketing.jsx" /* webpackChunkName: "component---src-pages-solutions-sales-and-marketing-jsx" */),
  "component---src-pages-solutions-size-jsx": () => import("./../../../src/pages/solutions/size.jsx" /* webpackChunkName: "component---src-pages-solutions-size-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-warehouse-management-index-jsx": () => import("./../../../src/pages/warehouse-management/index.jsx" /* webpackChunkName: "component---src-pages-warehouse-management-index-jsx" */),
  "component---src-pages-warehouse-management-multiple-warehouses-jsx": () => import("./../../../src/pages/warehouse-management/multiple-warehouses.jsx" /* webpackChunkName: "component---src-pages-warehouse-management-multiple-warehouses-jsx" */),
  "component---src-pages-warehouse-management-row-rack-bin-jsx": () => import("./../../../src/pages/warehouse-management/row-rack-bin.jsx" /* webpackChunkName: "component---src-pages-warehouse-management-row-rack-bin-jsx" */),
  "component---src-pages-warehouse-management-stock-transfer-and-adjustments-jsx": () => import("./../../../src/pages/warehouse-management/stock-transfer-and-adjustments.jsx" /* webpackChunkName: "component---src-pages-warehouse-management-stock-transfer-and-adjustments-jsx" */),
  "component---src-pages-webinars-jsx": () => import("./../../../src/pages/webinars.jsx" /* webpackChunkName: "component---src-pages-webinars-jsx" */),
  "component---src-pages-website-builder-jsx": () => import("./../../../src/pages/website-builder.jsx" /* webpackChunkName: "component---src-pages-website-builder-jsx" */),
  "component---src-pages-what-is-erp-jsx": () => import("./../../../src/pages/what-is-erp.jsx" /* webpackChunkName: "component---src-pages-what-is-erp-jsx" */),
  "component---src-pages-what-is-mrp-jsx": () => import("./../../../src/pages/what-is-mrp.jsx" /* webpackChunkName: "component---src-pages-what-is-mrp-jsx" */),
  "component---src-pages-why-deskera-jsx": () => import("./../../../src/pages/why-deskera.jsx" /* webpackChunkName: "component---src-pages-why-deskera-jsx" */),
  "component---src-pages-wip-tracking-index-jsx": () => import("./../../../src/pages/wip-tracking/index.jsx" /* webpackChunkName: "component---src-pages-wip-tracking-index-jsx" */),
  "component---src-pages-work-order-management-index-jsx": () => import("./../../../src/pages/work-order-management/index.jsx" /* webpackChunkName: "component---src-pages-work-order-management-index-jsx" */),
  "component---src-pages-workflow-automation-jsx": () => import("./../../../src/pages/workflow-automation.jsx" /* webpackChunkName: "component---src-pages-workflow-automation-jsx" */),
  "component---src-pages-workflow-jsx": () => import("./../../../src/pages/workflow.jsx" /* webpackChunkName: "component---src-pages-workflow-jsx" */),
  "component---src-pages-yield-finished-good-cost-index-jsx": () => import("./../../../src/pages/yield-finished-good-cost/index.jsx" /* webpackChunkName: "component---src-pages-yield-finished-good-cost-index-jsx" */)
}

