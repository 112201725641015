import './src/styles/global.css';
import wrapWithProvider from './wrap-with-provider';

export const onRouteUpdate = () => {
    if (typeof window !== 'undefined') {
        window.locations = window.locations || [document.referrer];
        locations.push(window.location.pathname);
        window.previousPath = locations[locations.length - 2];
    }
};

export const wrapRootElement = wrapWithProvider;
