// import { createBrowserHistory } from 'history';

// export const history = createBrowserHistory();

export const isPrerendering = false;
export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);

// Speed up calls to hasOwnProperty
const hasOwnProperty = Object.prototype.hasOwnProperty;

export function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) {
        return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) {
        return false;
    }
    if (obj.length === 0) {
        return true;
    }

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== `object`) {
        return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
        if (hasOwnProperty.call(obj, key)) {
            return false;
        }
    }

    return true;
}
export const Cookies = {
    get,
    set
};
function get(cname) {
    const name = cname + `=`;
    if (typeof window !== `undefined`) {
        const ca = document.cookie.split(`;`);
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ` `) {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
    }

    return ``;
}
function set(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=` + d.toUTCString();
    if (typeof window !== `undefined`) {
        document.cookie = cname + `=` + cvalue + `;` + expires + `;path=/`;
    }
}

export const getPlanRemainingDays = (nextchargeDate) => {
    const chargeDate = new Date(nextchargeDate);
    const diffInTime = chargeDate.getTime() - new Date().getTime();
    let inDays = Math.round(diffInTime / (1000 * 3600 * 24));
    if (inDays <= 0) {
        inDays = 0;
    }
    return inDays;
};

export function isBase64(str) {
    if (str === `` || str.trim() === ``) {
        return false;
    }
    try {
        return btoa(atob(str)) == str;
    } catch (err) {
        return false;
    }
}

export const isInViewport = (el) => {
    if (el === null) {
        return;
    }
    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = el.getBoundingClientRect().top + scroll;

    const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight
    };

    const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight
    };

    return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
};
