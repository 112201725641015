import ApiConstants from '../apis/ApiConstants';
import { PRODUCTS } from '../constants/Enum';

import { navigate } from 'gatsby';
import { KEY_NEED_JOIN_COMMUNITY } from '../constants/PersistentKeys';
import { getLocationBasedPageUrls, getPersistentValue } from '../utility/Utility';
import { pageUrls } from '../constants/Constants';

export default class AppManager {
    static joinNowPopupRequired = false;
    static persistentDataChecked = false;
    static didLandingPageLoaded = false;
    static cookieConscentAlertAdded = false;
    static currencyCode = null;
    static isLoadingLocation = false;

    static landingPageLoaded() {
        AppManager.didLandingPageLoaded = true;
    }

    static gotoProductDashboard(product) {
        let url = ApiConstants.PRODUCT_DASHBOARD.ALL_IN_ONE;
        if (product !== null) {
            switch (product.toLowerCase()) {
                case PRODUCTS.ERP:
                    url = ApiConstants.PRODUCT_DASHBOARD.BOOKS;
                    break;
                case PRODUCTS.CRM:
                    url = ApiConstants.PRODUCT_DASHBOARD.CRM;
                    break;
                case PRODUCTS.PEOPLE:
                    url = ApiConstants.PRODUCT_DASHBOARD.PEOPLE;
                    break;
                default:
                    url = ApiConstants.PRODUCT_DASHBOARD.ALL_IN_ONE;
            }
        }
        window.open(url, `_self`);
    }

    static gotoSigninPage(product = null) {
        let url = getLocationBasedPageUrls(pageUrls.SIGN_IN);
        if (product !== null) {
            url = url + `?product=` + product;
        }
        navigate(url);
    }

    static getContactUsUrl(product = null, plan = null, isTrial = null) {
        let url = getLocationBasedPageUrls(pageUrls.CONTACT_US) + `?trial=` + (isTrial === null || isTrial === undefined ? `false` : `false`);
        // let url =
        //   "/signup?trial=" +
        //   (isTrial === null || isTrial === undefined ? "true" : isTrial)
        if (product !== null) {
            url = url + `&product=` + product;
        }

        if (plan !== null) {
            url = url + `&plan=` + plan;
        }
        return url;
    }

    static getSignupURL(product = null, plan = null, isTrial = null) {
        let url = getLocationBasedPageUrls(pageUrls.SIGN_UP) + `?trial=` + (isTrial === null || isTrial === undefined ? `false` : `false`);
        // let url =
        //   "/signup?trial=" +
        //   (isTrial === null || isTrial === undefined ? "true" : isTrial)
        if (product !== null) {
            url = url + `&product=` + product;
        }

        if (plan !== null) {
            url = url + `&plan=` + plan;
        }
        return url;
    }
    static gotoSignupPage(product = null, plan = null, isTrial = null) {
        const url = AppManager.getSignupURL(product, plan, isTrial);
        navigate(url);

        // navigate("/signup")
    }

    static setJoinNowPopupVisibility(visible) {
        AppManager.joinNowPopupRequired = visible;
    }
    static isJoinNowPopupRequired() {
        return false;
        if (AppManager.persistentDataChecked === true && AppManager.joinNowPopupRequired === false) {
            return false;
        }

        AppManager.persistentDataChecked = true;
        if (getPersistentValue(KEY_NEED_JOIN_COMMUNITY) === null) {
            AppManager.setJoinNowPopupVisibility(true);
        } else {
            AppManager.setJoinNowPopupVisibility(getPersistentValue(KEY_NEED_JOIN_COMMUNITY));
            return getPersistentValue(KEY_NEED_JOIN_COMMUNITY);
        }
        return AppManager.joinNowPopupRequired;
    }

    static addCookieConsent = () => {
        if (AppManager.cookieConscentAlertAdded) {
            return;
        }

        AppManager.cookieConscentAlertAdded = true;
        setTimeout(() => {
            if (typeof window !== `undefined` && window.cookieconsent !== undefined && window.cookieconsent.run !== undefined) {
                window.cookieconsent.run({
                    notice_banner_type: `simple`,
                    consent_type: `express`,
                    palette: `light`,
                    language: `en`,
                    cookies_policy_url: `https://www.deskera.com/cookie-policy`
                });
            }
        }, 1000);
    };
    static getCurrencyCode = () => {
        return AppManager.currencyCode;
    };
    static setCurrencyCode = (currency) => {
        AppManager.currencyCode = currency;
    };
}
