import { API_STATUS, DEFAULT_CURRENCY } from '../constants/Enum';
import AppManager from '../managers/AppManager';
import DKCountryUtility from '../utility/DKCountryUtility';
import { Cookies } from '../utility/Helper';
import Utility, { getCountryWiseCurrency } from '../utility/Utility';
import ApiConstants from './ApiConstants';
import axios from 'axios';
export default class ApiManager {
    constructor() {
        axios.defaults.withCredentials = true;
    }
    static isLoginUser = false;
    static parseJwt(token) {
        const base64Url = token.split(`.`)[1];
        const base64 = base64Url.replace(/-/g, `+`).replace(/_/g, `/`);
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split(``)
                .map(function (c) {
                    return `%` + (`00` + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(``)
        );

        return JSON.parse(jsonPayload);
    }

    /// /////////////////////////////////////////
    /// /////////////////////////////////////////

    static getApiRequestOptions(method, body = null) {
        let requestOptions = {
            method: method,
            credentials: `include`,
            withCredentials: true,
            // mode: "cors",
            headers: {
                Accept: `application/json`,
                'Content-Type': `application/json`,
                'Access-Control-Allow-Origin': `*`
            }
        };
        if (method === `POST` || method === `PUT`) {
            body = JSON.stringify(body);
            requestOptions = { ...requestOptions, body };
        }
        return requestOptions;
    }
    /// /////////////////////////////////////////
    /// /////////////////////////////////////////

    static async checkIfUserLoggedIn() {
        await fetch(ApiConstants.URL.BASE + ApiConstants.URL.LOGIN_STATUS, ApiManager.getApiRequestOptions(`GET`))
            .then((response) => response.json())
            .then((data) => {
                ApiManager.setStatusResponse(data.accessToken).then((ApiManager.isLoginUser = true));
            })
            .catch((error) => {
                ApiManager.isLoginUser = false;
                console.log(`error`, error);
            });
    }
    // static parseJwt = (token) => {
    //     const base64Url = token.split(`.`)[1];
    //     const base64 = base64Url.replace(/-/g, `+`).replace(/_/g, `/`);
    //     const jsonPayload = decodeURIComponent(
    //         atob(base64)
    //             .split(``)
    //             .map(function (c) {
    //                 return `%` + (`00` + c.charCodeAt(0).toString(16)).slice(-2);
    //             })
    //             .join(``)
    //     );
    //     return JSON.parse(jsonPayload);
    // };
    static setStatusResponse = (token) => {
        const tokens = ApiManager.parseJwt(token);
        ApiManager.setUserDetails(tokens);
    };
    static setUserDetails(tokens) {
        ApiManager.userDetails = tokens;
    }
    static getUserDetails() {
        return ApiManager.userDetails;
    }
    static GoogleSignUp() {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.GET_STATE, ApiManager.getApiRequestOptions(`GET`))
            .then((response) => response.json())
            .then((data) => {
                if (data?.state) {
                    const googleapiurl =
                        ApiConstants.URL.GOOGLE_LOGIN + data?.state + `&access_type=offline&include_granted_scopes=true&promt=consent`;
                    window.location.href = googleapiurl;
                }
            })
            .catch((error) => {
                console.log(`GET_STATE `, error);
            });
    }
    static AppleSignUp() {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.GET_STATE, ApiManager.getApiRequestOptions(`GET`))
            .then((response) => response.json())
            .then((data) => {
                if (data?.state) {
                    const appleapiurl = ApiConstants.URL.APPLE_LOGIN + data?.state + `&scope=name%20email&response_mode=form_post&usePopup=true`;
                    window.location.href = appleapiurl;
                }
            })
            .catch((error) => {
                console.log(`GET_STATE `, error);
            });
    }

    /// /////////////////////////////////////////
    /// /////////////////////////////////////////

    static getPlanDetailsByID(planID, onSuccess, onFail) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.PLAN_DETAILS_BY_ID + planID, ApiManager.getApiRequestOptions(`GET`))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    if (onFail) {
                        onFail(data.errorMessage);
                    }
                    // alert(data.errorMessage)
                } else {
                    onSuccess(data);
                }
            })
            .catch((error) => {
                if (onFail) {
                    onFail(error);
                } else {
                    // alert(error)
                }
            });
    }

    static getPlanDetails(product, onSuccess, onFail) {
        const Currency = getCountryWiseCurrency();

        fetch(
            ApiConstants.URL.BASE + ApiConstants.URL.PLAN_DETAILS + product.toUpperCase() + `&Currency=${Currency}`,
            ApiManager.getApiRequestOptions(`GET`)
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    if (onFail) {
                        onFail(data.errorMessage);
                    }
                    // alert(data.errorMessage)
                } else {
                    onSuccess(data);
                }
            })
            .catch((error) => {
                if (onFail) {
                    onFail(error);
                } else {
                    // alert(error)
                }
            });
    }

    /// /////////////////////////////////////////
    /// /////////////////////////////////////////
    static userInvite(email, success, failed) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.INVITE, ApiManager.getApiRequestOptions(`POST`, email))
            .then((response) => response.json())
            .then((data) => {
                if (data.code == 400) {
                    if (data.errorMessage) {
                        failed(data.errorMessage);
                    } else {
                        failed(`Unexpected error, please try again!`);
                    }
                } else {
                    success(data);
                }
            })
            .catch((error) => {
                failed(`Unexpected error, please try again!`);
            });
    }
    static verifyInvite(bodyData, success, failed) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.VERIFY_INVITE, ApiManager.getApiRequestOptions(`POST`, bodyData))
            .then((response) => response.json())
            .then((data) => {
                if (data.code == 400) {
                    if (data.errorMessage) {
                        failed(data.errorMessage);
                    } else {
                        failed(`Unexpected error, please try again!`);
                    }
                } else {
                    success(data);
                }
            })
            .catch((error) => {
                failed(`Unexpected error, please try again!`);
            });
    }

    static signup(userData, success, failed) {
        try {
            axios
                .post(ApiConstants.URL.BASE + ApiConstants.URL.SIGNUP, userData)
                .then((response) => {
                    const data = response.data;
                    if (data?.code && [500, 400, 404, 409, 429].includes(data.code)) {
                        if (data.code === 429) {
                            failed(
                                'Hey 👋 It seems that there have been an excessive number of signup requests from your network. Please try again after some time, or consider using a different secure network. If the issue persists, kindly reach out to care@deskera.com with a screenshot of this message.'
                            );
                        } else if (data.errorMessage) {
                            if (data.errorMessage.startsWith('Sign up with domain')) {
                                failed(
                                    'Please use a corporate email to signup. The use of common emails is discouraged and the use of throwaway emails may result in you getting blacklisted'
                                );
                            } else {
                                failed(data.errorMessage);
                            }
                        } else {
                            failed(
                                'This request cannot be completed now. Please try again in some time. If the issue persists, kindly reach out to care@deskera.com with a screenshot of this message.'
                            );
                        }
                    } else {
                        success();
                    }
                })
                .catch((error) => {
                    failed(
                        'This request cannot be completed now. Please try again in some time. If the issue persists, kindly reach out to care@deskera.com with a screenshot of this message.'
                    );
                });
        } catch (error) {
            failed(
                'This request cannot be completed now. Please try again in some time. If the issue persists, kindly reach out to care@deskera.com with a screenshot of this message.'
            );
        }
    }

    static signIn(userData, success, failed) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.SIGNIN, ApiManager.getApiRequestOptions(`POST`, userData))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    failed(data.errorMessage);
                } else {
                    success(data);
                }
            })
            .catch((error) => {
                failed(`Unexpected error, please try again!`);
            });
    }

    /// /////////////////////////////////////////
    static mfa(userDetails, success, failed) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.MFA, ApiManager.getApiRequestOptions(`POST`, userDetails))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    const errorMsg = `Please try again. If still unsuccessful, please contact your administrator.`;
                    failed(errorMsg);
                } else {
                    success(data);
                }
            });
    }

    /// /////////////////////////////////////////

    static resendVerificationEmail(email, success, failure) {
        const encodedEmail = email;
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.RESEND_EMAIL_VERIFICATION + encodedEmail, ApiManager.getApiRequestOptions(`GET`))
            .then((response) => {
                if (response.status === API_STATUS.SUCCESS_200) {
                    return { success: true };
                }
                return { success: false };
            })
            .then((data) => {
                if (data.success) {
                    success();
                } else {
                    failure();
                }
            })
            .catch((error) => {
                failure();
            });
    }

    /// /////////////////////////////////////////
    /// /////////////////////////////////////////

    static initSubscription(success, failed) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.SUBSCRIPTION, ApiManager.getApiRequestOptions(`POST`))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    failed(data.errorMessage);
                } else {
                    success();
                }
            });
    }

    static activateSubscription(product, onSuccess, onFail) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.SUBSCRIPTION_PUT + product, ApiManager.getApiRequestOptions(`PUT`))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    onFail(data.errorMessage);
                } else {
                    onSuccess();
                }
            })
            .catch((error) => {
                // alert("Sign up success. Subscription not activated.")
                onFail(error);
            });
    }

    static activateSubscriptionByPlanID(planID, onSuccess, onFail) {
        axios
            .put(ApiConstants.URL.BASE + ApiConstants.URL.SUBSCRIPTION, {
                PlanID: planID,
                Currency: getCountryWiseCurrency()
            })
            .then((response) => {
                if (response.errorMessage) {
                    onFail(data.errorMessage);
                } else {
                    onSuccess();
                }
            })
            .catch((error) => {
                onFail(error?.message ? error?.message : error);
            });
    }

    static sendEmail(formdata, success, failure) {
        const requestData = new FormData();
        requestData.append(`body`, formdata.body);
        // requestData.append("recipientEmail", formdata.recipientEmail)
        requestData.append(`recipientEmail`, `jobs@deskera.com`);

        requestData.append(`subject`, formdata.subject);
        requestData.append(`attachmentName`, formdata.attachmentName);
        requestData.append(`attachment`, formdata.attachment);

        const requestOptions = {
            method: `POST`,
            body: requestData,
            redirect: `follow`
        };

        fetch(ApiConstants.URL.BASE + ApiConstants.URL.SEND_EMAIL, requestOptions)
            .then((response) => response.text())
            .then((data) => {
                if ((data.Errors && data.Errors.length > 0) || data.code) {
                    failure(`Something went wrong, please try again later.`);
                } else {
                    success(`Email sent successfully.`);
                }
            })
            .catch((error) => failure(`Something went wrong, please try again later.`));
    }

    static applyCouponCode(couponCode, success, failure) {
        fetch(ApiConstants.URL.BASE + ApiConstants.URL.APPLY_COUPON, ApiManager.getApiRequestOptions(`POST`, { Code: couponCode }))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    failure(data.errorMessage);
                } else {
                    success();
                }
            })
            .catch((error) => {
                failure(`Unexpected error, please try again!`);
            });
    }

    /// /////////////////////////////////////////
    /// /////////////////////////////////////////

    static checkIfSessionExpired(error, needToShowAlert = true) {
        if (error.toString() === `TypeError: Failed to fetch`) {
            alert(`Your session is expired. Please login again.`);
            // AppManager.gotoLoginPage();
        } else {
            if (needToShowAlert) {
                alert(error);
            }
        }
    }
    /// /////////////////////////////////////////
    /// /////////////////////////////////////////

    static getCountryByIP(ipAddress, success, failed) {
        let newURL = ApiConstants.URL.BASE + ApiConstants.URL.GET_COUNTRY;
        if (ipAddress) {
            newURL = newURL + `?ip=` + ipAddress;
        }
        fetch(newURL, ApiManager.getApiRequestOptions(`GET`))
            .then((response) => response.json())
            .then((data) => {
                success(data);
            })
            .catch((error) => {
                failed(error);
            });
    }
    static async getLocation() {
        const apiUrl = 'https://api.country.is/';
        try {
            const locURL = `${ApiConstants.URL.BASE}${ApiConstants.URL.GET_COUNTRY}`;
            const response = await axios.get(locURL);
            const countryCode = response.data?.country?.iso_code;
            return Promise.resolve(countryCode);
        } catch (error) {
            try {
                const response = await axios.get(apiUrl);
                const countriesData = response.data;
                return Promise.resolve(countriesData?.country);
            } catch (error) {
                return Promise.reject(error);
            }
        }
    }
    static getBDRSetting(timeZoneValue) {
        let newURL = ApiConstants.URL.BASE + ApiConstants.URL.BDR.SETTING;
        newURL = newURL + `/` + 25831 + `?tzOffset=` + timeZoneValue;
        return fetch(newURL, ApiManager.getApiRequestOptions(`GET`)).then((res) => res.json());
    }
}
