export const planDetailsV2 = {
    erp: {
        description: {},
        pricing: {
            in: 599,
            us: 99
        }
    },
    erp_plus: {
        description: {},
        pricing: {
            in: 1699,
            us: 199
        }
    },
    mrp_plus: {
        description: {},
        pricing: {
            in: 6999,
            us: 299
        }
    }
};
export const planDetails = {
    discount: 50,
    erp: {
        startup: {
            description: `Ideal for startups. Send invoices, track bills and expenses, and generate financial statements.`,
            features: [
                `Startup`,
                `Create 30 invoices & bills`,
                `Create recurring invoices`,
                `Collect payment online`,
                `Connect your bank`,
                `Invite your accountant`,
                `Add free users`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Startup`
                },
                in: {
                    planName: `Startup`
                },
                sg: {
                    planName: `Startup`
                },
                id: {
                    planName: `Startup`
                },
                my: {
                    planName: `Startup`
                }
            },
            pricing: {
                us: {
                    yearly: 49,
                    monthly: 59
                },
                in: {
                    yearly: 599,
                    monthly: 699
                },
                sg: {
                    yearly: 49,
                    monthly: 59
                },
                id: {
                    yearly: 110000,
                    monthly: 130000
                },
                my: {
                    yearly: 49,
                    monthly: 59
                }
            }
        },
        essential: {
            description: `Ideal for growing businesses and SMBs. Send more invoices, fulfill orders, track inventory.`,
            features: [
                `Unlimited invoices and bills`,
                `Create recurring invoices`,
                `Collect payment online`,
                `Connect your bank`,
                `Multi Currency`,
                `Invite your accountant`,
                `Add free users`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Essential`
                },
                in: {
                    planName: `Essential`
                },
                sg: {
                    planName: `Essential`
                },
                id: {
                    planName: `Essential`
                },
                my: {
                    planName: `Essential`
                }
            },
            pricing: {
                us: {
                    yearly: 99,
                    monthly: 119
                },
                in: {
                    yearly: 1699,
                    monthly: 1999
                },
                sg: {
                    yearly: 99,
                    monthly: 199
                },
                id: {
                    yearly: 199000,
                    monthly: 240000
                },
                my: {
                    yearly: 99,
                    monthly: 119
                }
            }
        },
        professional: {
            description: `A powerful solution for advanced users! Use custom fields to track data and create reports.`,
            features: [
                `Unlimited invoices and bills`,
                `Create recurring invoices`,
                `Collect payment online`,
                `Connect your bank`,
                `Multi Currency`,
                `Add your own custom fields`,
                `Get complete inventory management`,
                `Invite your accountant`,
                `Add free users`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Professional`
                },
                in: {
                    planName: `Professional`
                },
                sg: {
                    planName: `Professional`
                },
                id: {
                    planName: `Professional`
                },
                my: {
                    planName: `Professional`
                }
            },
            pricing: {
                us: {
                    yearly: 219,
                    monthly: 259
                },
                in: {
                    yearly: 3499,
                    monthly: 3999
                },
                sg: {
                    yearly: 219,
                    monthly: 259
                },
                id: {
                    yearly: 299000,
                    monthly: 360000
                },
                my: {
                    yearly: 219,
                    monthly: 259
                }
            }
        }
    },
    mrp: {
        startup: {
            description: `Ideal for startups. Send invoices, track bills and expenses, and generate financial statements.`,
            features: [
                `Startup`,
                `Create 30 invoices & bills`,
                `Create recurring invoices`,
                `Collect payment online`,
                `Connect your bank`,
                `Invite your accountant`,
                `Add free users`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Startup`
                },
                in: {
                    planName: `Startup`
                },
                sg: {
                    planName: `Startup`
                },
                id: {
                    planName: `Startup`
                },
                my: {
                    planName: `Startup`
                }
            },
            pricing: {
                us: {
                    yearly: 49,
                    monthly: 59
                },
                in: {
                    yearly: 599,
                    monthly: 699
                },
                sg: {
                    yearly: 49,
                    monthly: 59
                },
                id: {
                    yearly: 110000,
                    monthly: 130000
                },
                my: {
                    yearly: 49,
                    monthly: 59
                }
            }
        },
        essential: {
            description: `Ideal for growing businesses and SMBs. Send more invoices, fulfill orders, track inventory.`,
            features: [
                `Unlimited invoices and bills`,
                `Create recurring invoices`,
                `Collect payment online`,
                `Connect your bank`,
                `Multi Currency`,
                `Invite your accountant`,
                `Add free users`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Essential`
                },
                in: {
                    planName: `Essential`
                },
                sg: {
                    planName: `Essential`
                },
                id: {
                    planName: `Essential`
                },
                my: {
                    planName: `Essential`
                }
            },
            pricing: {
                us: {
                    yearly: 99,
                    monthly: 119
                },
                in: {
                    yearly: 1699,
                    monthly: 1999
                },
                sg: {
                    yearly: 99,
                    monthly: 199
                },
                id: {
                    yearly: 199000,
                    monthly: 240000
                },
                my: {
                    yearly: 99,
                    monthly: 119
                }
            }
        },
        professional: {
            description: `A powerful solution for advanced users! Use custom fields to track data and create reports.`,
            features: [
                `Unlimited invoices and bills`,
                `Create recurring invoices`,
                `Collect payment online`,
                `Connect your bank`,
                `Multi Currency`,
                `Add your own custom fields`,
                `Get complete inventory management`,
                `Invite your accountant`,
                `Add free users`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `MRP+`
                },
                in: {
                    planName: `MRP+`
                },
                sg: {
                    planName: `MRP+`
                },
                id: {
                    planName: `MRP+`
                },
                my: {
                    planName: `MRP+`
                }
            },
            pricing: {
                us: {
                    yearly: 2199,
                    monthly: 299
                },
                in: {
                    yearly: 3499,
                    monthly: 3999
                },
                sg: {
                    yearly: 219,
                    monthly: 259
                },
                id: {
                    yearly: 299000,
                    monthly: 360000
                },
                my: {
                    yearly: 219,
                    monthly: 259
                }
            }
        }
    },
    crm: {
        startup: {
            description: `Ideal for startups. Add contacts, close deals, and build sales pipelines.`,
            features: [
                `Unlimited Contacts`,
                `Manage all sales conversation`,
                `Create your own sales pipelines`,
                `Track sales activities`,
                `Add free users`,
                `Run Email Marketing campaigns`,
                `Email & chat support`,
                `No Code Landing Pages`
            ],
            displayName: {
                us: {
                    planName: `Startup`
                },
                in: {
                    planName: `Startup`
                },
                sg: {
                    planName: `Startup`
                },
                id: {
                    planName: `Startup`
                },
                my: {
                    planName: `Startup`
                }
            },
            pricing: {
                us: {
                    yearly: 39,
                    monthly: 49
                },
                in: {
                    yearly: 499,
                    monthly: 599
                },
                sg: {
                    yearly: 39,
                    monthly: 49
                },
                id: {
                    yearly: 99000,
                    monthly: 120000
                },
                my: {
                    yearly: 39,
                    monthly: 49
                }
            }
        },
        essential: {
            description: `Ideal for growing businesses and SMBs. Get the power of custom fields.`,
            features: [
                `Unlimited contacts`,
                `Manage all sales conversation`,
                `Create your sales pipelines`,
                `Track sales activities`,
                `Run email campaigns`,
                `Add free users`,
                `Custom Fields`,
                `Email & chat support`,
                `No Code Landing Pages`,
                `Drag and Drop Custom Forms`
            ],
            displayName: {
                us: {
                    planName: `Essential`
                },
                in: {
                    planName: `Essential`
                },
                sg: {
                    planName: `Essential`
                },
                id: {
                    planName: `Essential`
                },
                my: {
                    planName: `Essential`
                }
            },
            pricing: {
                us: {
                    yearly: 79,
                    monthly: 99
                },
                in: {
                    yearly: 999,
                    monthly: 1099
                },
                sg: {
                    yearly: 79,
                    monthly: 99
                },
                id: {
                    yearly: 150000,
                    monthly: 180000
                },
                my: {
                    yearly: 79,
                    monthly: 99
                }
            }
        },
        professional: {
            description: `For email marketing at scale. This plan allows you to expand your contact list and the number of emails sent.`,
            features: [
                `Unlimited Contacts`,
                `Manage all sales conversation`,
                `Create your sales pipelines`,
                `Track sales activities`,
                `Run email campaigns`,
                `Setup your personalized helpdesk`,
                `Add free users`,
                `Email & chat support`,
                `No Code Landing Pages`,
                `Drag and Drop Custom Forms`,
                `Sales Automation`,
                `Automate Tasks on Deal Stages`
            ],
            displayName: {
                us: {
                    planName: `Professional`
                },
                in: {
                    planName: `Professional`
                },
                sg: {
                    planName: `Professional`
                },
                id: {
                    planName: `Professional`
                },
                my: {
                    planName: `Professional`
                }
            },
            pricing: {
                us: {
                    yearly: 199,
                    monthly: 229
                },
                in: {
                    yearly: 1499,
                    monthly: 1999
                },
                sg: {
                    yearly: 199,
                    monthly: 229
                },
                id: {
                    yearly: 199000,
                    monthly: 240000
                },
                my: {
                    yearly: 199,
                    monthly: 229
                }
            }
        }
    },
    people: {
        startup: {
            description: `Ideal for startups. Automate payroll processing, set up pay schedule, invite employees, and send e-Payslips.`,
            features: [
                `Add up to 5 employees for free`,
                `Process weekly, bi-Weekly & monthly payroll`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Startup`
                },
                in: {
                    planName: `Startup`
                },
                sg: {
                    planName: `Startup`
                },
                id: {
                    planName: `Startup`
                },
                my: {
                    planName: `Startup`
                }
            },

            pricing: {
                us: {
                    yearly: 39,
                    monthly: 49
                },
                in: {
                    yearly: 499,
                    monthly: 599
                },
                sg: {
                    yearly: 39,
                    monthly: 49
                },
                id: {
                    yearly: 99000,
                    monthly: 120000
                },
                my: {
                    yearly: 39,
                    monthly: 49
                }
            }
        },
        essential: {
            description: `Ideal for growing businesses and SMBs. Run payroll for more employees. Setup your leave rules.`,
            features: [
                `Add up to 10 employees for free`,
                `Process weekly, bi-Weekly & monthly payroll`,
                `Design your payslip`,
                `Apply and approve leaves`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Essential`
                },
                in: {
                    planName: `Essential`
                },
                sg: {
                    planName: `Essential`
                },
                id: {
                    planName: `Essential`
                },
                my: {
                    planName: `Essential`
                }
            },
            pricing: {
                us: {
                    yearly: 79,
                    monthly: 99
                },
                in: {
                    yearly: 999,
                    monthly: 1099
                },
                sg: {
                    yearly: 79,
                    monthly: 99
                },
                id: {
                    yearly: 150000,
                    monthly: 180000
                },
                my: {
                    yearly: 79,
                    monthly: 99
                }
            }
        },
        professional: {
            description: `Run payroll, track leave & attendance, and integrated expense reporting.`,
            features: [
                `Add up to 15 employees for free`,
                `Process weekly, bi-Weekly & monthly payroll`,
                `Design your payslip`,
                `Apply and approve leaves`,
                `Submit and approve expenses`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`
            ],
            displayName: {
                us: {
                    planName: `Professional`
                },
                in: {
                    planName: `Professional`
                },
                sg: {
                    planName: `Professional`
                },
                id: {
                    planName: `Professional`
                },
                my: {
                    planName: `Professional`
                }
            },
            pricing: {
                us: {
                    yearly: 199,
                    monthly: 229
                },
                in: {
                    yearly: 1499,
                    monthly: 1999
                },
                sg: {
                    yearly: 199,
                    monthly: 229
                },
                id: {
                    yearly: 199000,
                    monthly: 240000
                },
                my: {
                    yearly: 199,
                    monthly: 229
                }
            }
        }
    },
    aio: {
        startup: {
            description: `The perfect choice for your new business. Setup integrated Accounting, CRM and Payroll in minutes.`,
            features: [
                `All In One Dashboard`,
                `Create 30 invoices & bills`,
                `Auto bank reconciliation`,
                `Run Payroll for 5 employees`,
                `Create your own sales pipelines`,
                `Run Email Marketing campaigns`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`,
                `No Code Landing Pages`
            ],
            displayName: {
                us: {
                    planName: `Startup`
                },
                in: {
                    planName: `Startup`
                },
                sg: {
                    planName: `Startup`
                },
                id: {
                    planName: `Startup`
                },
                my: {
                    planName: `Startup`
                }
            },

            pricing: {
                us: {
                    yearly: 83,
                    monthly: 100
                },
                in: {
                    yearly: 1199,
                    monthly: 1499
                },
                sg: {
                    yearly: 99,
                    monthly: 109
                },
                id: {
                    yearly: 220000,
                    monthly: 265000
                },
                my: {
                    yearly: 99,
                    monthly: 109
                }
            }
        },
        essential: {
            description: `The most popular choice for SMBs and growing companies. Suitable for growing businesses.`,
            features: [
                `All In One Dashboard`,
                `Unlimited invoices and bills`,
                `Auto bank reconciliation`,
                `Multi currency`,
                `Run payroll for 10 employees`,
                `Apply and approve leaves`,
                `Create your own sales pipelines`,
                `Run email campaigns`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`,
                `No Code Landing Pages`,
                `Drag and Drop Custom Forms`
            ],

            displayName: {
                us: {
                    planName: `Essential`
                },
                in: {
                    planName: `Essential`
                },
                sg: {
                    planName: `Essential`
                },
                id: {
                    planName: `Essential`
                },
                my: {
                    planName: `Essential`
                }
            },

            pricing: {
                us: {
                    yearly: 200,
                    monthly: 450
                },
                in: {
                    yearly: 2999,
                    monthly: 3499
                },
                sg: {
                    yearly: 199,
                    monthly: 249
                },
                id: {
                    yearly: 349000,
                    monthly: 420000
                },
                my: {
                    yearly: 199,
                    monthly: 249
                }
            }
        },
        professional: {
            description: `The ideal choice for companies that need advanced features and custom reporting.`,
            features: [
                `All In One Dashboard`,
                `Unlimited invoices and bills`,
                `Auto bank reconciliation`,
                `Multi currency`,
                `Add your own custom fields`,
                `Get complete inventory management`,
                `Run payroll for 15 employees`,
                `Apply and approve leaves & expenses`,
                `Create your own sales pipelines`,
                `Run email campaigns`,
                `Email & chat support`,
                `Access to Help Center & Deskera Academy`,
                `No Code Landing Pages`,
                `Drag and Drop Custom Forms`,
                `Sales Automation`,
                `Automate Tasks on Deal Stages`
            ],
            displayName: {
                us: {
                    planName: `ERP+`
                },
                in: {
                    planName: `ERP+`
                },
                sg: {
                    planName: `ERP+`
                },
                id: {
                    planName: `ERP+`
                },
                my: {
                    planName: `ERP+`
                }
            },
            pricing: {
                us: {
                    yearly: 2000,
                    monthly: 199
                },
                in: {
                    yearly: 5999,
                    monthly: 599
                },
                sg: {
                    yearly: 499,
                    monthly: 599
                },
                id: {
                    yearly: 499000,
                    monthly: 599000
                },
                my: {
                    yearly: 499,
                    monthly: 599
                }
            }
        }
    }
};
export const BannerplanDetails = {
    pricing: {
        us: {
            yearly: 200
        },
        in: {
            yearly: 599
        },
        sg: {
            yearly: 49
        },
        id: {
            yearly: 110000
        },
        my: {
            yearly: 39
        }
    }
};
export const planMRP = {
    pricing: {
        us: {
            yearly: 3000,
            monthly: 299
        },
        in: {
            yearly: 599,
            monthly: 599
        },
        sg: {
            yearly: 49,
            monthly: 599
        },
        id: {
            yearly: 110000,
            monthly: 599
        },
        my: {
            yearly: 39,
            monthly: 599
        }
    }
};
