import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiManager from '../apis/ApiManager';
import { Cookies } from '../utility/Helper';
import { API_STATUS } from '../constants/Enum';

const initialState = {
    location: `US`
};
export const fetchLocation = createAsyncThunk('fetchLocation', async () => {
    const response = await ApiManager.getLocation();

    return response;
});
export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocation.pending, (state, action) => {
                state.status = API_STATUS.LOADING;
                state.location = initialState.location;
            })
            .addCase(fetchLocation.fulfilled, (state, action) => {
                state.status = API_STATUS.SUCCESS;

                let locationData = action.payload;

                if (locationData) {
                    Cookies.set(`location`, locationData, 1);
                    Cookies.set(`country_iso_code`, locationData, 1);
                } else {
                    Cookies.set(`location`, initialState?.location, 1);
                    Cookies.set(`country_iso_code`, initialState?.location, 1);
                }

                state.location = locationData;
            });
    }
});

// Action creators are generated for each case reducer function

// export const { increment, decrement, incrementByAmount } = locationSlice.actions;
export const locationValue = (state) => (state?.location ? state?.location : initialState.location);
export default locationSlice.reducer;
