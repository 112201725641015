export default class ApiConstants {
    static URL = {
        BASE: process.env.GATSBY_BASE_URL,

        LOGIN_REDIRECT: process.env.GATSBY_LOGIN_URL,
        INTERCOM_BASE_URL: process.env.GATSBY_INTERCOM_BASE_URL,

        LOGIN_STATUS: `iam/auth/sign-in/login/status`,
        PLAN_DETAILS_BY_ID: `anonymous/subengine/plan?pid=`,
        PLAN_DETAILS: `anonymous/subengine/plans?Version=v2&Product=`,
        RESEND_EMAIL_VERIFICATION: `iam/auth/email/regenerate?email=`,

        SUBSCRIPTION: `subengine/subscription`,
        SUBSCRIPTION_PUT: `subengine/subscription?Trial=`,

        PHONE_NUMBER: `subengine/billing/phone`,
        PHONE_OTP: `subengine/billing/phoneotp`,

        INVITE: `iam/auth/user/invite`,
        VERIFY_INVITE: `iam/auth/user/verify-invite`,
        SIGNUP: `iam/auth/sign-up/web`,
        SIGNIN: `iam/auth/sign-in/web/sign-in`,
        LOGOUT: `iam/auth/logout`,
        MFA: `iam/auth/sign-in/web/mfa-signin`,
        GET_STATE: `state/public/generate`,

        APPLY_COUPON: `subengine/coupon/redeem`,

        CRM_FORM: `crm/core/deskera/open/form/`,

        SEND_EMAIL: `anonymous/document-designer/email`,

        STRIPE: {
            GET_INTENT: `subengine/billing/setupintent`,
            SET_PAYMENT: `subengine/billing/paymentmethod`
        },

        ADYEN: {
            SET_PAYMENT: `subengine/billing/a/paymentmethod`
        },

        GET_PAYMENT_METHOD: `subengine/billing/paymentmethod`,

        CASHFREE: {
            REQUEST_PAGE: `subengine/billing/c/paymentmethod`
        },
        BDR: {
            SETTING: `crm/book-meeting/openep/bdr`
        },
        WHATSAPP: {
            WHATSAPP_URL: (whatsapp) => `https://wa.me/${whatsapp}`
        },

        GET_COUNTRY: `anonymous/location`,
        REPLY_IO_URL: `intercom/reply-io/deskera/open/website/api/`,

        AC_API_BASE_URL: process.env.GATSBY_AC_API_BASE_URL,
        GOOGLE_LOGIN: process.env.GATSBY_GOOGLE_LOGIN,
        APPLE_LOGIN: process.env.GATSBY_APPLE_LOGIN
    };

    static IS_CASHFREE_GLOBALLY_ENABLED = process.env.GATSBY_IS_CASHFREE_GLOBALLY_ENABLED;

    static PRODUCT_DASHBOARD = {
        BOOKS: process.env.GATSBY_PRODUCT_BOOKS,
        CRM: process.env.GATSBY_PRODUCT_CRM,
        PEOPLE: process.env.GATSBY_PRODUCT_PEOPLE,
        ALL_IN_ONE: process.env.GATSBY_PRODUCT_ALL_IN_ONE
    };
}
